@charset "utf-8";
@import "../../../modules/_import";

//table wrap
[class*="tbl-cmn"] {
  overflow: auto;
  width: 100%;
  //table
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 1px;
    background-color: $color-b2;
  }
  th,
  td {
    text-align: left;
    vertical-align: top;
    background-color: #fff;
  }
  th {
    background-color: lighten($color-t2, 50%);
    color: darken($color-t2, 10%);
  }
  td {
  }
  thead th {
    background-color: $color-t2;
    color: #fff;
  }
}
@include media(sm-max) {
  [class*="tbl-cmn"],
  [class*="tbl-cmn"] th,
  [class*="tbl-cmn"] td {
    display: block;
  }
  [class*="tbl-cmn"] {
    td {
      padding: $gutter / 4;
      border-top: 1px solid $color-b2;
    }
    th {
      padding: $gutter / 4;
      background-color: $color-t2;
      color: #fff;
    }
    th + td {
      border-top: 0 none;
    }
  }
  .tbl-cmn--add {
    thead {
      display: none;
    }
    td {
      text-align: right;
    }
    td::before {
      content: attr(data-title);
      float: left;
      color: darken($color-t2, 10%);
    }
  }
}
@include media(sm) {
  [class*="tbl-cmn"] th,
  [class*="tbl-cmn"] td {
    padding: $gutter / 2;
  }
}

//standard
[class*="tbl-std"] {
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 1px;
    background-color: $color-b2;
  }
  th,
  td {
    background-color: #fff;
  }
  thead th {
    background-color: $color-t2;
    color: #fff;
  }
  tbody th {
    background-color: lighten($color-t4, 20%);
    color: $color-t1;
  }
  caption {
    margin-bottom: .5em;
    font-size: 120%;
    font-weight: bold;
    &::before {
      content: "■";
      color: $color-t3;
    }
  }
  .noCaps {
    margin-bottom: .5em;
    font-size: 80%;
    font-weight: normal;
    &::before {
      display: none;
    }
  }
  @include media(xs) {
    th,
    td {
      padding: $gutter / 4;
    }
    .tbl-cmn--row,
    .tbl-cmn--row tr,
    .tbl-cmn--row th,
    .tbl-cmn--row td {
      display: block;
    }
    .tbl-cmnB--row,
    .tbl-cmnB--row tr,
    .tbl-cmnB--row th,
    .tbl-cmnB--row td {
      display: block;
    }
  }
  @include media(sm) {
    th,
    td {
      padding: $gutter / 2;
    }
  }
}


//data table
[class*="tbl-data"] {
  table {
    width: 100%;
    background-color: $color-b3;
    border-collapse: separate;
    border-spacing: 1px;
  }
  th,
  td {
    padding: .5em;
    vertical-align: middle;
    background-color: $color-b3;
  }
  thead th,
  thead td {
    line-height: 1.1;
    text-align: center;
    background-color: $color-t1;
    color: $color-b3;
  }
  thead td {
    font-size: 90%;
  }
  tbody th {
    font-size: 90%;
    color: $color-t1;
  }
  tbody tr:nth-of-type(even) > * {
    background-color: lighten($color-t4, 20%);
  }
  tbody td.u-ta-c {
    font-size: 150%;
    font-weight: bold;
    line-height: 1;
    color: $color-t5;
  }
  @include media(sm-max) {
    tbody th {
      width: 26%;
    }
    tbody td {
      width: 32%;
      white-space: nowrap;
    }
  }
  @include media(md) {
    tbody th {
      width: 25%;
    }
    tbody td {
      width: 30%;
      white-space: nowrap;
    }
    tbody td + td {
      width: 45%;
    }
  }
  caption {
    margin-bottom: .5em;
    padding-left: .5em;
    border-left: 3px solid $color-t3;
    font-size: 90%;
    font-weight: bold;
    color: $color-t1;
    em {
      font-size: 1.6rem;
      color: $color-t5;
    }
    strong {
      font-size: 120%;
    }
  }
  .box-scroll + .box-scroll {
    margin-top: 1.5em;
  }
}