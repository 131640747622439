@charset "utf-8";
@import "../../modules/_import";

/* 2-4. Order [flex-order]
-------------------------------------------------- */
/* header */
.header-logo { order: 1; }
.g-nav       { order: 4; }
.g-address   { order: 2; }
.l-mark      { order: 3; }
@include media(xl) {
  .g-nav       { order: 2; }
  .g-address   { order: 3; }
  .l-mark      { order: 4; }
}