@charset "utf-8";
@import "../../../modules/_import";

.tblist {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}


//flow
.lst-flow--item {
  position: relative;
  counter-increment: step;
  & + & {
    margin-top: 45px;
  }
  &::before {
    content: counter(step);
    position: absolute;
    top:  -.9em;
    left: -.9em;
    display: flex;
    justify-content: center;
    align-items: center;
    width:  2em;
    height: 2em;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1;
    border-radius: 100%;
    background-color: $color-t2;
    color: #fff !important;
  }
  &:not(:last-of-type)::after {
    content: "";
    display: block;
    position: absolute;
    right:  0;
    bottom: -60px;
    left:   0;
    margin: auto;
    width:  0;
    height: 0;
    border-width: 24px 18px;
    border-style: solid;
    border-color: $color-t5 transparent transparent;
  }
  @include media(md) {
    width: calc(50% - 15px);
    &:not(:last-of-type)::after {
      content: none;
    }
    &:nth-of-type(odd) {
      clear: left;
      float: left;
      margin-top: 0;
      margin-bottom: 60px;
    }
    &:nth-of-type(even) {
      clear: right;
      float: right;
      margin-top: 60px;
    }
    &:nth-of-type(4),
    &:nth-of-type(6) {
      margin-top: 150px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
.lst-step {
  padding: 15px;
  border-left: 4px solid $color-t2;
  background-color: lighten($color-t2, 50%);
  &__head {
    font-size: 1.8rem;
    font-weight: 500;
    color: $color-t2;
  }
}