@charset "utf-8";
@import "../../../modules/_import";

.gBnrs {
  &Wrap {
    border-top: 1px solid $color-b3;
    @include media(md-max){
      padding-bottom: $gutter / 2;
    }
    @include media(lg){
      padding-bottom: $gutter / 2;
    }
  }
  &-item {
    @include media(md-max){
      width: 100%;
      padding: $gutter / 2;
      padding-bottom: 0;
    }
    @include media(lg){
      padding: $gutter $gutter / 2 $gutter / 2;
    }
    text-align: center;
  }
  a {
    display: block;
  }
  [class*="btn-"] {
    padding-right: 0;
    padding-left:  0;
  }
}