@charset "utf-8";
@import "../../../modules/_import";

[class*="prg-"] {
  padding-top:   $gutter / 4;
  line-height: 2.0;
}
[class*="prg-"] p + p{
  margin-top: $gutter / 4;
}
.prg-xl { font-size: 2.0rem; line-height: 1.2; }
.prg-l  { font-size: 1.8rem; line-height: 1.2; }
.prg-m  { font-size: 1.5rem; line-height: 1.8; }
.prg-s  { font-size: 1.3rem; line-height: 1.8; }
.prg-xs { font-size: 1.1rem; line-height: 1.5; }
  
  
@include media(sm-max) {
  [class*="prg-"] .richtext p br {
    display: none;
  }
}
@include media(md) {
  [class*="prg-"] {
    padding-top:   $gutter / 2;
    line-height: 2.0;
  }
  [class*="prg-"] p + p{
    margin-top: $gutter / 2;
  }
  .prg-xl { font-size: 1.8rem; line-height: 1.2; }
  .prg-l  { font-size: 1.6rem; line-height: 1.5; }
  .prg-m  { font-size: 1.4rem; line-height: 1.8; }
  .prg-s  { font-size: 1.3rem; line-height: 1.8; }
  .prg-xs { font-size: 1.1rem; line-height: 1.5; }
}
@include media(lg) {
  .prg-xl { font-size: 2.4rem; }
  .prg-l  { font-size: 1.8rem; }
  .prg-m  { font-size: 1.6rem; }
  .prg-s  { font-size: 1.4rem; }
  .prg-xs { font-size: 1.1rem; line-height: 1.5; }
}

//richtext
[class*="prg-"] {
  .richtext p a {
    text-decoration: underline;
  }
}