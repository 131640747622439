@charset "utf-8";
@import "../../../modules/_import";
//phone
.lnk-tel {
  display: inline-flex;
  color: inherit !important;
  @include media(sm-max) {
    pointer-events: auto;
  }
  @include media(md) {
    pointer-events: none;
  }
}
//fax
.lnk-fax {
  display: inline-block;
  pointer-events: none;
}