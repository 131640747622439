@charset "utf-8";
@import "../../../modules/_import";

[class*="mda-"] {
  position: relative;
  img {
    max-width: 100%;
  }
  figure {
    display: inline-block;
    position: relative;
  }
  figcaption {
    display: inline-block;
  }
}
/* shadow */
.mda-fig__sdw,
.mda-fig__flt {
  margin-top:    $gutter / 2;
  margin-bottom: $gutter / 2;
  @include media(md) {
    margin-top:    $gutter / 2;
    margin-right:  $gutter * 1.5;
  }
  figure::before {
    opacity: .75;
    content: "";
    z-index: -1;
    position: absolute;
    top:  5px;
    left: 5px;
    width:  100%;
    height: 100%;
    margin: auto;
    background-image: linear-gradient(45deg, $color-t2 0%, $color-t1 100%);
    background-color: $color-t2;
    @include media(md) {
      top:  15px;
      left: 15px;
    }
  }
}
.mda-fig__flt {
  @include media(md) {
    float: left;
    float: left;
    max-width: 50%;
  }
}
/* cut */
.mda-fig__cut {
  position: relative;
  &::before,
  &::after {
    z-index: 2;
    content:"";
    display: block;
    position: absolute;
    width:  $gutter;
    height: $gutter;
    background-repeat: no-repeat;
    background-size: auto 100%;
    @include media(md) {
      width:  $gutter * 2;
      height: $gutter * 2;
    }
  }
  &::before {
    top: 0;
    left: 0;
    background-image: url(../common_img/bg-lt.png);
    background-position: left top;
  }
  &::after {
    right: 0;
    bottom: 0;
    background-image: url(../common_img/bg-rb.png);
    background-position: right bottom;
  }
}