@charset "utf-8";
@import "../../modules/_import";

/* 0-1. Base
-------------------------------------------------- */
body {
  min-width: 320px;

  font-family: $ff-base;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5;

  background-color: $color-body;
  color: $color-text;

  @include media(xl) {
    min-width: 1200px;
  }
}

body *,
body *::before,
body *::after {
  box-sizing: border-box;
}

a {
  word-break: break-all;
  background-color: transparent;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
          tap-highlight-color: transparent;
}
a:link {
  text-decoration: none;
  color: $color-link;
}
a:visited {
  text-decoration: none;
  color: $color-visited;
}
a:hover, a:active {
  outline-width: 0;
  text-decoration: none;
  color: $color-hover;
}
a:focus {
  outline-width: 0;
  text-decoration: none;
}
li img, li a, li a img {
  vertical-align: bottom;
}

/* 0-2. Config
-------------------------------------------------- */
/* 0-2-1.  font family */
.u-ff-rale { font-family: $ff-rale }
.u-ff-noto { font-family: $ff-noto; }

/* 0-2-2.  color management */
/* base-color */
.color-b1 { color: rgba($color-b1, 1); } .bgc-b1 { background-color: rgba($color-b1, 1); }
.color-b2 { color: rgba($color-b2, 1); } .bgc-b2 { background-color: rgba($color-b2, 1); }
.color-b3 { color: rgba($color-b3, 1); } .bgc-b3 { background-color: rgba($color-b3, 1); }
.color-b4 { color: rgba($color-b4, 1); } .bgc-b4 { background-color: rgba($color-b4, 1); }
/* thema-color */
.color-t1 { color: rgba($color-t1, 1); } .bgc-t1 { background-color: rgba($color-t1, 1); }
.color-t2 { color: rgba($color-t2, 1); } .bgc-t2 { background-color: rgba($color-t2, 1); }
.color-t3 { color: rgba($color-t3, 1); } .bgc-t3 { background-color: rgba($color-t3, 1); }
.color-t4 { color: rgba($color-t4, 1); } .bgc-t4 { background-color: rgba($color-t4, 1); }
.color-t5 { color: rgba($color-t5, 1); } .bgc-t5 { background-color: rgba($color-t5, 1); }
.color-t6 { color: rgba($color-t6, 1); } .bgc-t6 { background-color: rgba($color-t6, 1); }

/* 0-2-3.  lightbox */
a[data-lightbox="lb"] {
  display: inline-block;
  position: relative;
  text-decoration: none !important;
  pointer-events: none;
}
@include media(md) {
  a[data-lightbox="lb"] {
    pointer-events: auto;
  }
  a[data-lightbox="lb"]::after {
    position: absolute;
    bottom: 5px;
    right: 5px;
    padding: 5px 10px;
    background-color: rgba(0,0,0,.50);
    @include fontAwesome;
    content:"\f002";
    color: #fff;
    text-decoration: none !important;
  }
}
