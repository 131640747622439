@charset "utf-8";
@import "../../../modules/_import";

.pagination {
  margin-top:    $gutter / 2;
  margin-bottom: $gutter / 2;
  @include media(md){
    margin-top:    $gutter * 1.5;
    margin-bottom: $gutter * 1.5;
  }
  text-align: center;
}
.pager {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left:  auto;
  text-align: center;
}
.pagenation {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  & > * {
    overflow: hidden;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width:  3em;
    height: 3em;
    margin: 2px;
    border-radius: 4px;
    font-weight: bold;
    line-height: 1;
  }
  .current {
    overflow: hidden;
    border-radius: 4px;
    background-color: #fff;
    border: 2px solid $color-t3;
    color: $color-t3;
  }
  a {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: calc(100%);
    height: calc(100%);
    margin: -2px;
    line-height: 1;
    text-align: center;
    text-decoration: none !important;
    background-color: $color-t2;
    box-shadow: 1px 1px 3px rgba(0,0,0,.15);
    border: 2px solid $color-t2;
    color: #fff;
    transition: background .3s, box-shadow .3s, border .3s;
    &:hover {
      background-color: $color-t3;
      border-color: $color-t3;
      box-shadow: none;
    }
  }
}
@include media(sm-max) {
  .pager {
    padding-top: $gutter;
  }
  .pagenation > * {
    width:  2.4em;
    height: 2.4em;
  }

}
@include media(md) {
  .pager {
    padding-top:    40px;
    padding-bottom: 20px;
  }
  .pagenation > * {
    width:  3em;
    height: 3em;
  }
}
.page.is-hidden {
  display: none;
}
.page.is-visible {
  display: inline-flex;
}