@charset "utf-8";
@import "../../../modules/_import";

[class*="hdg"] {
  font-weight: 700;
}

@include media(xs) {
    [class*="hdg1"] { font-size: 2.8rem; }
    [class*="hdg2"] { font-size: 2.4rem; }
    [class*="hdg3"] { font-size: 2.0rem; }
    [class*="hdg4"] { font-size: 1.8rem; }
    [class*="hdg5"] { font-size: 1.6rem; }
    [class*="hdg6"] { font-size: 1.4rem; }
}
@include media(sm) {
    [class*="hdg1"] { font-size: 3.6rem; }
    [class*="hdg2"] { font-size: 3.2rem; }
    [class*="hdg3"] { font-size: 2.8rem; }
    [class*="hdg4"] { font-size: 2.0rem; }
    [class*="hdg5"] { font-size: 1.8rem; }
    [class*="hdg6"] { font-size: 1.6rem; }
}
@include media(md) {
    [class*="hdg1"] { font-size: 4.8rem; }
    [class*="hdg2"] { font-size: 3.6rem; }
    [class*="hdg3"] { font-size: 3.2rem; }
    [class*="hdg4"] { font-size: 2.4rem; }
    [class*="hdg5"] { font-size: 2.0rem; }
    [class*="hdg6"] { font-size: 1.8rem; }
}