@charset "utf-8";
@import "../../../modules/_import";

[class*="btns"] {
  display: flex;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;
  padding-top: $gutter;
  font-size: 1.6rem;
}
// plus arrow
.bArw::before,
.aArw::after {
  position: absolute;
  top:   auto;
  bottom: auto;
  @include fontAwesome;
  content: "\f105";
  font-size: 120%;
}
.bArw::before {
  right: 1em;
}
.aArw::after {
  left:  1em;
}
// plus return
.bRet::before {
  position: absolute;
  top:   auto;
  bottom: auto;
  @include fontAwesome;
  content: "\f104";
  font-size: 120%;
}
.bRet::before {
  left: 1em;
}

/* color */
[class*="btn-color"] {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 2px solid rgba(#ffffff, .30);
  padding: 1.2em 3em;
  background-image: linear-gradient(180deg, rgba(#fff, .10) 50%, transparent 51%);
  background-color: #111;
  color: #fff !important;
  text-shadow: 1px 1px 0 rgba(#000, .30);

  transition: background .3s, color .3s;
}

    .btn-color--b1 {
      background-color: $color-b1;
      &.is-hover {
        background-color: lighten($color-b1, 10%);
      }
    }
    .btn-color--b2 {
      background-color: $color-b2;
      &.is-hover {
        background-color: lighten($color-b2, 5%);
      }
    }
    .btn-color--b3 {
      border: 2px solid rgba($color-b4, .10);
      background-color: $color-b3;
      color: $color-b1 !important;
      text-shadow: 1px 1px 0 rgba($color-b1, .30);
      &.is-hover {
        background-color: lighten($color-b3, 5%);
      }
    }
    .btn-color--b4 {
      background-color: $color-b4;
      color: $color-t1 !important;
      text-shadow: 1px 1px 0 rgba($color-t1, .30);
      &.is-hover {
        background-color: lighten($color-t1, 5%);
        color: $color-b4 !important;
      }
    }
    .btn-color--t1 {
      background-color: $color-t1;
      &.is-hover {
        background-color: lighten($color-t1, 5%);
      }
    }
    .btn-color--t2 {
      background-color: $color-t2;
      &.is-hover {
        background-color: lighten($color-t2, 5%);
      }
    }
    .btn-color--t3 {
      background-color: $color-t3;
      &.is-hover {
        background-color: lighten($color-t3, 5%);
      }
    }
    .btn-color--t4 {
      background-color: darken($color-t4, 10%);
      &.is-hover {
        background-color: $color-t4;
      }
    }
    .btn-color--t5 {
      background-color: $color-t5;
      &.is-hover {
        background-color: $color-t4;
      }
    }
    .btn-color--t6 {
      background-color: darken($color-t6, 10%);
      color: darken($color-t5, 10%) !important;
      &.is-hover {
        background-color: $color-t6;
      }
    }

/* color */
[class*="btn-frame"] {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  padding: 1em 3em;
  background-color: transparent;
  color: $color-b1 !important;

  transition: border .3s, background .3s, color .3s;
}

    .btn-frame--b1 {
      border-color: rgba($color-b1, .65);
      &.is-hover {
        border-color: $color-b1;
        background-color: $color-b1;
        color: #fff !important;
      }
    }
    .btn-frame--b2 {
      border-color: rgba($color-b2, .65);
      &.is-hover {
        border-color: $color-b2;
        background-color: $color-b2;
      }
    }
    .btn-frame--b3 {
      border-color: rgba($color-b3, .65);
      &.is-hover {
        border-color: $color-b3;
        background-color: $color-b3;
      }
    }
    .btn-frame--b4 {
      border-color: rgba($color-b4, .65);
      &.is-hover {
        border-color: $color-b4;
        background-color: $color-b4;
        color: $color-t1 !important;
      }
    }
    .btn-frame--t1 {
      border-color: rgba($color-t1, .65);
      color: $color-t1 !important;
      &.is-hover {
        border-color: $color-t1;
        background-color: $color-t1;
        color: #fff !important;
      }
    }
    .btn-frame--t2 {
      border-color: rgba($color-t2, .65);
      color: $color-t2 !important;
      &.is-hover {
        border-color: $color-t2;
        background-color: $color-t2;
        color: #fff !important;
      }
    }
    .btn-frame--t3 {
      border-color: rgba(darken($color-t3, 10%), .65);
      color: darken($color-t3, 10%) !important;
      &.is-hover {
        border-color: $color-t3;
        background-color: $color-t3;
        color: #fff !important;
      }
    }
    .btn-frame--t4 {
      border-color: rgba($color-t4, .65);
      color: $color-t4 !important;
      &.is-hover {
        background-color: $color-t4;
        color: #fff !important;
      }
    }
    .btn-frame--t5 {
      border-color: rgba($color-t5, .65);
      color: $color-t5 !important;
      &.is-hover {
        background-color: $color-t5;
        color: #fff !important;
      }
    }
    .btn-frame--t6 {
      border-color: rgba( darken($color-t6, 30%), .65);
      color: darken($color-t6, 40%) !important;
      &.is-hover {
        background-color: darken($color-t6, 30%);
        color: #fff !important;
      }
    }