@charset "utf-8";
@import "../../../modules/_import";

/* page */
.ttl-page {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-top: 60px;
  background-image: url(../common_img/bg-patturn.png);
  background-repeat: repeat;
  background-position: 0 0;
  background-size: 128px 96px;
  background-color: $color-t2;
  @include media(md) {
    margin-top: 80px;
    background-size: 640px 480px;
  }
  & > * {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top:    $gutter * 1.5;
    padding-bottom: $gutter * 1.5;
    background-image: -webkit-linear-gradient(left, rgba($color-t1, .80) 0%, rgba($color-t2, .10) 50%);
    background-image: linear-gradient(to right, rgba($color-t1, .80) 0%, rgba($color-t2, .10) 50%);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100%;
    text-shadow: 2px 2px 0 rgba(darken($color-t1,30%), .30);
    color: #fff;
    @include media(md) {
      padding-top:    $gutter * 2;
      padding-bottom: $gutter * 2;
    }
    &::after {
      opacity: .15;
      position: absolute;
      top: 0;
      right: -15px;
      bottom: 0;
      margin: auto;
      display: block;
      content: "";
      width:  192px;
      height: 192px;
      background-image: url(../common_img/ico-logo.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      @include media(sm) {
        width:  240px;
        height: 240px;
      }
    }
  }
}
// basic
.ttl {
  text-align: center;
  margin-top:    $gutter / 2;
  margin-bottom: $gutter / 2;
  @include media(md){
    margin-top:    $gutter;
    margin-bottom: $gutter;
  }
}
// main
[class*="ttl-main"] {
  position: relative;
  margin-top:    $gutter / 2;
  margin-bottom: $gutter / 2;
  padding: .25em;
  line-height: 1.2;
  text-align: center;
  background-color: $color-t2;
  background-image: -webkit-linear-gradient(left, rgba($color-t1, .80) 0%, rgba($color-t2, .10) 50%);
  background-image: linear-gradient(to right, rgba($color-t1, .80) 0%, rgba($color-t2, .10) 50%);
  background-repeat: no-repeat;
  background-size: 100%;
  color: #fff;
  @include media(md){
    margin-top:    $gutter;
    margin-bottom: $gutter;
  }
  &::before {
    z-index: -1;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 200%;
    height: 100%;
    background-color: $color-t2;
  }
  &::after {
    z-index: 2;
    content: "";
    display: block;
    position: absolute;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background-repeat: no-repeat;
    background-size: auto 100%;
  }
}
.ttl-main--lt {
  &::before {
    right: -100%;
  }
  &::after {
    left: 0;
    background-image: url(../common_img/bg-lt.png);
    background-position: left top;
  }
}
.ttl-main--rb {
  &::before {
    background-image: linear-gradient(to right, rgba($color-t1, .80) 0%, rgba($color-t1, .80) 50%);
    left: -100%;
  }
  &::after {
    right: 0;
    background-image: url(../common_img/bg-rb.png);
    background-position: right bottom;
  }
}
// main
[class*="ttl-sub"] {
  display: inline-block;
  position: relative;
  margin-top:    $gutter / 2;
  margin-bottom: $gutter / 2;
  padding-bottom: $gutter / 2;
  @include media(md){
    margin-top:    $gutter;
    margin-bottom: $gutter;
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    margin: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: $color-t2;
  }
  
}
// bullet
[class*="ttl-bullet"] {
  position: relative;
  margin-top:    $gutter / 2;
  margin-bottom: $gutter / 2;
  line-height: 1.2;
  @include media(sm) {
    margin-top:    $gutter;
    margin-bottom: $gutter;
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    margin: auto;
    background-color: $color-t2;
  }
}
.ttl-bullet--ud {
  padding-bottom: $gutter / 2;
  text-align: center;
  &::after {
    right: 0;
    bottom: 0;
    left: 0;
    width: 3em;
    height: 4px;
  }
}
.ttl-bullet--lt {
  padding-left: .5em;
  &::after {
    top: 0;
    bottom: 0;
    left: 0;
    width: 4px;
    height: 100%;
  }
}
//line
.ttl-upperline,
.ttl-underline {
  font-weight: normal;
  font-weight: 500;
  color: $color-t2;
}
.ttl-upperline {
  padding-top: .25em;
  border-top:    1px solid $color-t2;
}
.ttl-underline {
  border-bottom: 1px solid $color-t2;
}