@charset "utf-8";
@import "../../../modules/_import";

#breadcrumb {
  ul {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: $containerWidth - 60px;
    margin-right: auto;
    margin-left:  auto;
    padding: ($gutter / 2);
  }
  li {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    color: rgba($color-b1, .80);
  }
  li + li::before {
    display: inline-block;
    float: left;
    content: "";
    width:  6px;
    height: 6px;
    margin: .5em;
    border-top: 1px solid $color-b1;
    border-right: 1px solid $color-b1;
    transform: rotate(45deg);
  }
  a {
    display: block;
    text-decoration: underline;
    white-space: nowrap;
    color: $color-t2;
    &:hover {
      color: $color-t3;
      text-decoration: none;
    }
  }
  li:nth-of-type(n+3) span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @include media(sm-max) {
    li,
    li a {
      font-size: 11px;
    }
    li + li::before {
      width: .4rem;
      height: .4rem;
    }
    li:nth-of-type(1) a span {
      display: none;
    }
    li:nth-of-type(1) a::before {
      @include fontAwesome;
      content: "\f015";
    }
    li:nth-of-type(n+3) span {
      max-width: 9em;
    }
  }
  @include media(md) {
    li:nth-of-type(n+3) span {
      max-width: 20em;
    }
  }
}