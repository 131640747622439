@charset "UTF-8";
/*! -----------------------------------------------
common css 共通クラス
--------------------------------------------------
0. Foundation
   0-1. Base
   0-2. Config
        0-2-1. font family
        0-2-2. color management
        0-2-3. lightbox
1. Layout
   1-1.  .l-wrapper
   1-2.  .l-container
   1-3.  .l-header
   1-4.  .l-footer
   1-5.  .l-nav
        1-4-1. .globalNav
        1-4-2. .footerNav
   1-6.  .l-mark
        1-6-1. .mark
   1-7.  .l-main
2. Object
   2-1. Component
        2-1-1.  Wrap      [***Wrap]
        2-1-2.  Box       [box-***]
        2-1-3.  Headings  [hdg-***]
        2-1-4.  Title     [ttl-***]
        2-1-5.  Paragraph [prg-***]
        2-1-6.  Table     [tbl-***]
        2-1-7.  List      [lst-***]
        2-1-8.  Nav       [nav-***]
        2-1-9.  Button    [btn-***]
        2-1-10. Link      [lnk-***]
        2-1-11. Icon      [ico-***]
        2-1-12. Label     [lbl-***]
        2-1-13. Form      [frm-***]
        2-1-14. Media     [mda-***]
        2-1-15. Card      [crd-***]
        2-1-16. Breadcrumb
        2-1-17. Pagination
   2-2. Animation [act-***]
   2-3. OverLayerConfig [z-index]
   2-4. Order [flex-order]
*/
/* -----------------------------------------------
 [0. Foundation]
-------------------------------------------------- */
/* 0-1. Base
-------------------------------------------------- */
body {
  min-width: 320px;
  font-family: "Noto Sans Japanese", "メイリオ", Meiryo, sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  color: #232323;
}

@media (min-width: 1200px) {
  body {
    min-width: 1200px;
  }
}

body *,
body *::before,
body *::after {
  box-sizing: border-box;
}

a {
  word-break: break-all;
  background-color: transparent;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
}

a:link {
  text-decoration: none;
  color: #8fc31f;
}

a:visited {
  text-decoration: none;
  color: #8fc31f;
}

a:hover, a:active {
  outline-width: 0;
  text-decoration: none;
  color: #c4e564;
}

a:focus {
  outline-width: 0;
  text-decoration: none;
}

li img, li a, li a img {
  vertical-align: bottom;
}

/* 0-2. Config
-------------------------------------------------- */
/* 0-2-1.  font family */
.u-ff-rale {
  font-family: "Raleway", sans-serif;
}

.u-ff-noto {
  font-family: "Noto Sans Japanese", sans-serif;
}

/* 0-2-2.  color management */
/* base-color */
.color-b1 {
  color: #232323;
}

.bgc-b1 {
  background-color: #232323;
}

.color-b2 {
  color: #dadada;
}

.bgc-b2 {
  background-color: #dadada;
}

.color-b3 {
  color: #eeeeee;
}

.bgc-b3 {
  background-color: #eeeeee;
}

.color-b4 {
  color: white;
}

.bgc-b4 {
  background-color: white;
}

/* thema-color */
.color-t1 {
  color: #62b800;
}

.bgc-t1 {
  background-color: #62b800;
}

.color-t2 {
  color: #8fc31f;
}

.bgc-t2 {
  background-color: #8fc31f;
}

.color-t3 {
  color: #c4e564;
}

.bgc-t3 {
  background-color: #c4e564;
}

.color-t4 {
  color: #ea2d27;
}

.bgc-t4 {
  background-color: #ea2d27;
}

.color-t5 {
  color: #edb41e;
}

.bgc-t5 {
  background-color: #edb41e;
}

.color-t6 {
  color: #fdefb5;
}

.bgc-t6 {
  background-color: #fdefb5;
}

/* 0-2-3.  lightbox */
a[data-lightbox="lb"] {
  display: inline-block;
  position: relative;
  text-decoration: none !important;
  pointer-events: none;
}

@media (min-width: 768px) {
  a[data-lightbox="lb"] {
    pointer-events: auto;
  }
  a[data-lightbox="lb"]::after {
    position: absolute;
    bottom: 5px;
    right: 5px;
    padding: 5px 10px;
    background-color: rgba(0, 0, 0, 0.5);
    font-family: 'FontAwesome';
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    text-rendering: auto;
    line-height: 1;
    content: "\f002";
    color: #fff;
    text-decoration: none !important;
  }
}

/* -----------------------------------------------
 [1. Layout]
-------------------------------------------------- */
/* 1-1.  .l-wrapper
-------------------------------------------------- */
.l-wrapper {
  position: relative;
  overflow: hidden;
}

.l-standby {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
}

@media (max-width: 767px) {
  .l-standby {
    height: 60vh;
  }
}

@media (min-width: 768px) {
  .l-standby {
    height: 480px;
  }
}

.l-standby br {
  display: block !important;
}

.no-article {
  display: none;
}

/* 1-2.  .l-container
-------------------------------------------------- */
.l-container {
  position: relative;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.l-container__fluid {
  position: relative;
  max-width: 100%;
  padding-right: 0;
  padding-left: 0;
}

/* 1-3.  .l-header
-------------------------------------------------- */
.l-header {
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-top: 4px solid #8fc31f;
  background-color: rgba(255, 255, 255, 0.95);
}

@media (max-width: 575px) {
  .l-header {
    min-width: 320px;
    height: 60px;
  }
}

@media (min-width: 576px) {
  .l-header {
    min-width: auto;
    height: 60px;
  }
}

@media (min-width: 768px) {
  .l-header {
    min-width: auto;
    height: 80px;
  }
}

@media (min-width: 1200px) {
  .l-header {
    height: 80px;
    padding-right: 15px;
  }
}

.header-logo {
  margin-right: auto;
  margin-left: 15px;
  line-height: 1;
}

@media (max-width: 767px) {
  .header-logo img {
    height: 30px;
  }
}

@media (min-width: 768px) {
  .header-logo img {
    height: 40px;
  }
}

/* 1-4.  l-footer
-------------------------------------------------- */
.g-footer {
  border-bottom: 4px solid #62b800;
}

.l-footer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

@media (max-width: 767px) {
  .l-footer {
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .l-footer .fInfo {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .l-footer {
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .l-footer .fInfo {
    width: 100%;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  .l-footer {
    max-width: 960px;
    justify-content: space-between;
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .l-footer .fInfo {
    width: auto;
  }
  .l-footer .gAdr-items {
    justify-content: flex-start;
  }
}

@media (min-width: 1200px) {
  .l-footer {
    max-width: 1200px;
    justify-content: space-between;
    padding-top: 45px;
    padding-bottom: 45px;
  }
}

.l-footer .no-wrap {
  width: 100%;
  flex-wrap: nowrap;
}

.footer-logo {
  margin-right: auto;
  line-height: 1;
}

.footer-logo a {
  display: inline-block;
}

@media (max-width: 767px) {
  .footer-logo {
    margin-top: 0;
    margin-left: auto;
    text-align: center;
  }
  .footer-logo img {
    height: 30px;
  }
}

@media (min-width: 768px) {
  .footer-logo {
    margin-top: 15px;
    margin-left: 15px;
    text-align: left;
  }
  .footer-logo img {
    height: 40px;
  }
}

@media (min-width: 992px) {
  .footer-logo {
    margin-top: 0;
  }
}

.footer-address {
  margin-top: 1em;
}

@media (max-width: 991px) {
  .footer-address {
    display: none;
  }
}

@media (min-width: 992px) {
  .footer-address {
    padding-right: 15px;
    padding-left: 15px;
    font-size: 1.3rem;
  }
}

/* pagetop */
.pagetop {
  border-top: 1px solid rgba(218, 218, 218, 0.5);
  border-bottom: 1px solid rgba(218, 218, 218, 0.5);
  background-color: rgba(238, 238, 238, 0.5);
}

.pagetop a {
  display: block;
  font-size: 3.6rem;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  background-color: #8fc31f;
  color: #fff;
  transition: background .3s;
}

@media (max-width: 767px) {
  .pagetop a {
    padding-top: 4px;
    padding-bottom: 6px;
  }
}

@media (min-width: 768px) {
  .pagetop a {
    padding-top: 8px;
    padding-bottom: 12px;
  }
}

@media (min-width: 992px) {
  .pagetop a.is-hover {
    background-color: #b3e34c;
    color: #fff;
  }
}

/* 1-5.  .l-nav
-------------------------------------------------- */
.l-nav {
  display: flex;
  width: 100vw;
}

/* 1-5-1. .globalNav */
.g-nav {
  display: flex;
  justify-content: flex-end;
  height: 100%;
}

.g-nav {
  width: 100vw;
}

.gNav-lnk__txt {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;
  padding-right: 15px;
  padding-left: 15px;
  font-weight: bold;
  color: #232323 !important;
  transition: background .3s;
}

.g-nav__bg {
  display: none;
  opacity: 0;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity .3s;
}

.is-active .g-nav__bg {
  display: block;
  opacity: 1;
}

@media (max-width: 1199px) {
  .g-nav {
    width: 100vw;
    height: 100vh;
    transform: translateX(100%);
    transition: transform .3s;
  }
  .is-active .g-nav {
    transform: translateX(0);
  }
  .gNav-items {
    overflow: auto;
    width: 100%;
    height: 100%;
    padding-right: 15px;
    padding-left: 15px;
    background-color: rgba(255, 255, 255, 0.95);
  }
  .gNav-item {
    border-bottom: 1px solid #dadada;
  }
  .gNav-item:first-of-type {
    border-top: 1px solid #dadada;
  }
  .gNav-lnk__txt {
    display: block;
    padding: 15px;
    text-align: center;
    color: #8fc31f !important;
  }
}

@media (max-width: 575px) {
  .l-nav {
    width: 100%;
    height: calc(100vh - 60px);
  }
}

@media (min-width: 576px) {
  .l-nav {
    width: 100%;
    height: calc(100vh - 60px);
  }
}

@media (min-width: 768px) {
  .l-nav {
    max-width: 320px;
    height: calc(100vh - 80px);
  }
  .gNav-btn,
  .gNav-tel {
    display: none;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .gNav-lnk__txt {
    padding-right: 13px;
    padding-left: 13px;
  }
}

@media (min-width: 1200px) {
  .l-nav {
    width: auto;
    max-width: 100%;
    height: auto;
    margin-right: 0;
    margin-left: 0;
  }
  .g-nav {
    position: relative;
    width: auto;
  }
  .gNav-items {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
  }
  .gNav-lnk__txt::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 0;
    height: 0;
    border: 8px solid transparent;
  }
  .gNav-lnk__txt:hover {
    background-color: #8fc31f;
    background-image: linear-gradient(0deg, #62b800 0%, #8fc31f 100%);
    color: #fff !important;
  }
  .owl-in_home.gNav-lnk__txt,
  .owl-in_page_tree.gNav-lnk__txt {
    color: #8fc31f !important;
  }
  .owl-in_home.gNav-lnk__txt:hover,
  .owl-in_page_tree.gNav-lnk__txt:hover {
    background-color: transparent;
    background-image: none;
  }
  .owl-in_home.gNav-lnk__txt::before,
  .owl-in_page_tree.gNav-lnk__txt::before {
    border-top-color: #8fc31f;
  }
  .g-nav__bg,
  .is-active .g-nav__bg {
    display: none;
  }
  .gNav-item:nth-of-type(7) {
    display: none;
  }
}

/* 1-5-2. .headerAddress */
.g-address {
  display: flex;
  height: 100%;
}

.gAdr-item__num {
  display: block;
  margin-top: 12px;
  margin-right: 15px;
  font-weight: bold;
  line-height: 1.2;
  color: #8fc31f;
}

.gAdr-item__num .gAdr-numbers--time {
  color: #999;
}

.gNav-btn__mail {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  font-weight: 500;
  line-height: 1.2;
  border-radius: 4px;
  background-color: #edb41e;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  color: #fff !important;
  transition: background .3s;
}

.gNav-btn__mail span {
  font-size: 1.3rem;
}

.gNav-btn__mail i {
  font-size: 3.6rem;
}

@media (max-width: 767px) {
  .l-header .gAdr-items,
  .l-footer .gAdr-numbers--fax {
    display: none;
  }
  .gAdr-item__btn,
  .gNav-btn {
    margin-top: 15px;
  }
  .gNav-btn__mail {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .gNav-btn__mail br {
    display: none;
  }
  .gNav-btn__mail span {
    font-size: 1.6rem;
  }
  .gNav-btn__mail i {
    font-size: 2.4rem;
  }
  .gAdr-numbers--tel,
  .gNav-tel {
    margin-top: 15px;
    text-align: center;
  }
  .gAdr-numbers--tel .lnk-tel,
  .gNav-tel .lnk-tel {
    flex-direction: column;
    font-size: 2.0rem;
    font-weight: bold;
    line-height: 1.1;
    color: #8fc31f !important;
  }
  .gAdr-numbers--tel br,
  .gNav-tel br {
    display: none;
  }
  .gAdr-numbers--tel span:nth-of-type(2),
  .gNav-tel span:nth-of-type(2) {
    display: inline-block;
    margin-top: .5em;
    font-weight: 400;
    color: #999 !important;
  }
  .gAdr-numbers--time {
    font-weight: normal;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .gAdr-items {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
  }
  .gAdr-item__btn {
    padding-top: 12px;
  }
}

@media (min-width: 992px) {
  .gAdr-items {
    position: relative;
    margin-left: 15px;
  }
  .gNav-btn__mail:hover {
    background-color: #ea2d27;
  }
}

/* 1-5-3. .footerNav */
.fNav {
  width: 100%;
}

.fNav-items {
  display: flex;
  justify-content: flex-end;
}

.fNav-item {
  padding-right: 15px;
  padding-left: 15px;
}

@media (max-width: 991px) {
  .fNav {
    display: none;
  }
}

@media (min-width: 992px) {
  .fNav-item a {
    color: #232323 !important;
  }
  .fNav-item a:hover {
    color: #8fc31f !important;
  }
  .fNav-item__head {
    display: block;
    margin-top: 10px;
    font-weight: bold;
  }
  .fNav-item__head + .fNav-item__head {
    margin-top: 15px;
  }
  .fNav-item__desc ul {
    padding-top: .25em;
  }
  .fNav-item__desc li {
    padding-top: .25em;
    color: #8fc31f;
  }
}

/* 1-6. .l-mark
-------------------------------------------------- */
.l-mark {
  position: relative;
  overflow: hidden;
  margin-left: 15px;
  font-size: 2.4rem;
  text-decoration: none;
  vertical-align: middle;
  text-align: center;
  transition: .3s;
  cursor: pointer;
}

@media (max-width: 767px) {
  .l-mark {
    width: 56px;
    height: 56px;
    margin-left: 8px;
  }
}

@media (min-width: 768px) {
  .l-mark {
    width: 76px;
    height: 76px;
    margin-left: 15px;
  }
}

@media (min-width: 1200px) {
  .l-mark {
    display: none;
  }
}

/* 1-6-1. .mark */
.mark {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #8fc31f;
}

.mark.is-active {
  background-color: transparent;
}

.mark > * {
  position: relative;
  text-decoration: none;
}

.mark > i {
  will-change: transform;
}

.markMenu {
  width: 100%;
  height: 1.8rem;
  position: relative;
}

.markMenu__middle, .markMenu__top, .markMenu__bottom {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 2px;
  background-color: #fff;
  transition-duration: .4s;
  transition-timing-function: cubic-bezier(0.25, 0.74, 0.22, 0.99);
}

.markMenu__middle {
  margin-top: -2px;
  transition: opacity .25s;
  opacity: 1;
}

.markMenu__top {
  margin-top: -9px;
}

.markMenu__bottom {
  margin-top: 5px;
}

.mark.is-active .markMenu__middle,
.mark.is-active .markMenu__top,
.mark.is-active .markMenu__bottom {
  background-color: #62b800;
}

.mark.is-active .markMenu__middle {
  opacity: 0;
}

.mark.is-active .markMenu__top {
  transform: rotate(45deg) translate(5px, 5px);
}

.mark.is-active .markMenu__bottom {
  transform: rotate(-45deg) translate(5px, -5px);
}

.l-mark.is-fixed .markMenu__middle,
.l-mark.is-fixed .markMenu__top,
.l-mark.is-fixed .markMenu__bottom {
  background-color: #fff;
}

/* 1-7.  .l-main
-------------------------------------------------- */
/* -----------------------------------------------
 [2. Object]
-------------------------------------------------- */
/* 2-1-1.  Wrap      [***Wrap]
-------------------------------------------------- */
.l-wrapper:not(.page-) section [class*="Wrap"] {
  padding-top: 30px;
  padding-bottom: 45px;
}

@media (min-width: 576px) {
  .l-wrapper:not(.page-) section [class*="Wrap"] {
    padding-top: 45px;
    padding-bottom: 45px;
  }
}

.l-wrapper:not(.page-) section:first-of-type [class*="Wrap"] {
  padding-top: 0;
}

@media (min-width: 576px) {
  .l-wrapper:not(.page-) section:first-of-type [class*="Wrap"] {
    padding-top: 15px;
  }
}

/* line - bg */
.grayLine,
.greenLine {
  background-repeat: repeat;
  background-position: 0 0;
  background-size: 640px 480px;
}

.grayLine > [class*="Wrap"],
.greenLine > [class*="Wrap"] {
  position: relative;
  background-image: -webkit-linear-gradient(left, rgba(98, 184, 0, 0.8) 0%, rgba(143, 195, 31, 0.1) 50%);
  background-image: linear-gradient(to right, rgba(98, 184, 0, 0.8) 0%, rgba(143, 195, 31, 0.1) 50%);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100%;
}

.grayLine > [class*="Wrap"]::before,
.greenLine > [class*="Wrap"]::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 10%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.grayLine {
  background-image: url(../common_img/bg-patturn-g.png);
  background-color: #eeeeee;
}

.grayLine > [class*="Wrap"] {
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(238, 238, 238, 0.1) 50%);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(238, 238, 238, 0.1) 50%);
}

.grayLine [class$="Txt"] {
  color: #fff;
}

.greenLine {
  background-image: url(../common_img/bg-patturn.png);
  background-color: #8fc31f;
}

.greenLine > [class*="Wrap"] {
  background-image: -webkit-linear-gradient(left, rgba(98, 184, 0, 0.8) 0%, rgba(143, 195, 31, 0.1) 50%);
  background-image: linear-gradient(to right, rgba(98, 184, 0, 0.8) 0%, rgba(143, 195, 31, 0.1) 50%);
}

.greenLine [class$="Txt"] {
  color: #fff;
}

/* 2-1-2.  Box       [box-***]
-------------------------------------------------- */
.box-scroll {
  overflow: auto;
  width: 100%;
}

/* 2-1-3.  Headings  [hdg-***]
-------------------------------------------------- */
[class*="hdg"] {
  font-weight: 700;
}

@media (max-width: 575px) {
  [class*="hdg1"] {
    font-size: 2.8rem;
  }
  [class*="hdg2"] {
    font-size: 2.4rem;
  }
  [class*="hdg3"] {
    font-size: 2.0rem;
  }
  [class*="hdg4"] {
    font-size: 1.8rem;
  }
  [class*="hdg5"] {
    font-size: 1.6rem;
  }
  [class*="hdg6"] {
    font-size: 1.4rem;
  }
}

@media (min-width: 576px) {
  [class*="hdg1"] {
    font-size: 3.6rem;
  }
  [class*="hdg2"] {
    font-size: 3.2rem;
  }
  [class*="hdg3"] {
    font-size: 2.8rem;
  }
  [class*="hdg4"] {
    font-size: 2.0rem;
  }
  [class*="hdg5"] {
    font-size: 1.8rem;
  }
  [class*="hdg6"] {
    font-size: 1.6rem;
  }
}

@media (min-width: 768px) {
  [class*="hdg1"] {
    font-size: 4.8rem;
  }
  [class*="hdg2"] {
    font-size: 3.6rem;
  }
  [class*="hdg3"] {
    font-size: 3.2rem;
  }
  [class*="hdg4"] {
    font-size: 2.4rem;
  }
  [class*="hdg5"] {
    font-size: 2.0rem;
  }
  [class*="hdg6"] {
    font-size: 1.8rem;
  }
}

/* 2-1-4.  Title     [ttl-***]
-------------------------------------------------- */
/* page */
.ttl-page {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-top: 60px;
  background-image: url(../common_img/bg-patturn.png);
  background-repeat: repeat;
  background-position: 0 0;
  background-size: 128px 96px;
  background-color: #8fc31f;
}

@media (min-width: 768px) {
  .ttl-page {
    margin-top: 80px;
    background-size: 640px 480px;
  }
}

.ttl-page > * {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 45px;
  padding-bottom: 45px;
  background-image: -webkit-linear-gradient(left, rgba(98, 184, 0, 0.8) 0%, rgba(143, 195, 31, 0.1) 50%);
  background-image: linear-gradient(to right, rgba(98, 184, 0, 0.8) 0%, rgba(143, 195, 31, 0.1) 50%);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100%;
  text-shadow: 2px 2px 0 rgba(17, 31, 0, 0.3);
  color: #fff;
}

@media (min-width: 768px) {
  .ttl-page > * {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.ttl-page > *::after {
  opacity: .15;
  position: absolute;
  top: 0;
  right: -15px;
  bottom: 0;
  margin: auto;
  display: block;
  content: "";
  width: 192px;
  height: 192px;
  background-image: url(../common_img/ico-logo.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@media (min-width: 576px) {
  .ttl-page > *::after {
    width: 240px;
    height: 240px;
  }
}

.ttl {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .ttl {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

[class*="ttl-main"] {
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: .25em;
  line-height: 1.2;
  text-align: center;
  background-color: #8fc31f;
  background-image: -webkit-linear-gradient(left, rgba(98, 184, 0, 0.8) 0%, rgba(143, 195, 31, 0.1) 50%);
  background-image: linear-gradient(to right, rgba(98, 184, 0, 0.8) 0%, rgba(143, 195, 31, 0.1) 50%);
  background-repeat: no-repeat;
  background-size: 100%;
  color: #fff;
}

@media (min-width: 768px) {
  [class*="ttl-main"] {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

[class*="ttl-main"]::before {
  z-index: -1;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 200%;
  height: 100%;
  background-color: #8fc31f;
}

[class*="ttl-main"]::after {
  z-index: 2;
  content: "";
  display: block;
  position: absolute;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.ttl-main--lt::before {
  right: -100%;
}

.ttl-main--lt::after {
  left: 0;
  background-image: url(../common_img/bg-lt.png);
  background-position: left top;
}

.ttl-main--rb::before {
  background-image: linear-gradient(to right, rgba(98, 184, 0, 0.8) 0%, rgba(98, 184, 0, 0.8) 50%);
  left: -100%;
}

.ttl-main--rb::after {
  right: 0;
  background-image: url(../common_img/bg-rb.png);
  background-position: right bottom;
}

[class*="ttl-sub"] {
  display: inline-block;
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

@media (min-width: 768px) {
  [class*="ttl-sub"] {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

[class*="ttl-sub"]::after {
  content: "";
  display: block;
  position: absolute;
  margin: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #8fc31f;
}

[class*="ttl-bullet"] {
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 1.2;
}

@media (min-width: 576px) {
  [class*="ttl-bullet"] {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

[class*="ttl-bullet"]::after {
  content: "";
  display: block;
  position: absolute;
  margin: auto;
  background-color: #8fc31f;
}

.ttl-bullet--ud {
  padding-bottom: 15px;
  text-align: center;
}

.ttl-bullet--ud::after {
  right: 0;
  bottom: 0;
  left: 0;
  width: 3em;
  height: 4px;
}

.ttl-bullet--lt {
  padding-left: .5em;
}

.ttl-bullet--lt::after {
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
  height: 100%;
}

.ttl-upperline,
.ttl-underline {
  font-weight: normal;
  font-weight: 500;
  color: #8fc31f;
}

.ttl-upperline {
  padding-top: .25em;
  border-top: 1px solid #8fc31f;
}

.ttl-underline {
  border-bottom: 1px solid #8fc31f;
}

/* 2-1-5.  Paragraph [prg-***]
-------------------------------------------------- */
[class*="prg-"] {
  padding-top: 7.5px;
  line-height: 2.0;
}

[class*="prg-"] p + p {
  margin-top: 7.5px;
}

.prg-xl {
  font-size: 2.0rem;
  line-height: 1.2;
}

.prg-l {
  font-size: 1.8rem;
  line-height: 1.2;
}

.prg-m {
  font-size: 1.5rem;
  line-height: 1.8;
}

.prg-s {
  font-size: 1.3rem;
  line-height: 1.8;
}

.prg-xs {
  font-size: 1.1rem;
  line-height: 1.5;
}

@media (max-width: 767px) {
  [class*="prg-"] .richtext p br {
    display: none;
  }
}

@media (min-width: 768px) {
  [class*="prg-"] {
    padding-top: 15px;
    line-height: 2.0;
  }
  [class*="prg-"] p + p {
    margin-top: 15px;
  }
  .prg-xl {
    font-size: 1.8rem;
    line-height: 1.2;
  }
  .prg-l {
    font-size: 1.6rem;
    line-height: 1.5;
  }
  .prg-m {
    font-size: 1.4rem;
    line-height: 1.8;
  }
  .prg-s {
    font-size: 1.3rem;
    line-height: 1.8;
  }
  .prg-xs {
    font-size: 1.1rem;
    line-height: 1.5;
  }
}

@media (min-width: 992px) {
  .prg-xl {
    font-size: 2.4rem;
  }
  .prg-l {
    font-size: 1.8rem;
  }
  .prg-m {
    font-size: 1.6rem;
  }
  .prg-s {
    font-size: 1.4rem;
  }
  .prg-xs {
    font-size: 1.1rem;
    line-height: 1.5;
  }
}

[class*="prg-"] .richtext p a {
  text-decoration: underline;
}

/* 2-1-6.  Table     [tbl-***]
-------------------------------------------------- */
[class*="tbl-cmn"] {
  overflow: auto;
  width: 100%;
}

[class*="tbl-cmn"] table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 1px;
  background-color: #dadada;
}

[class*="tbl-cmn"] th,
[class*="tbl-cmn"] td {
  text-align: left;
  vertical-align: top;
  background-color: #fff;
}

[class*="tbl-cmn"] th {
  background-color: #f4fbe6;
  color: #6f9718;
}

[class*="tbl-cmn"] thead th {
  background-color: #8fc31f;
  color: #fff;
}

@media (max-width: 767px) {
  [class*="tbl-cmn"],
  [class*="tbl-cmn"] th,
  [class*="tbl-cmn"] td {
    display: block;
  }
  [class*="tbl-cmn"] td {
    padding: 7.5px;
    border-top: 1px solid #dadada;
  }
  [class*="tbl-cmn"] th {
    padding: 7.5px;
    background-color: #8fc31f;
    color: #fff;
  }
  [class*="tbl-cmn"] th + td {
    border-top: 0 none;
  }
  .tbl-cmn--add thead {
    display: none;
  }
  .tbl-cmn--add td {
    text-align: right;
  }
  .tbl-cmn--add td::before {
    content: attr(data-title);
    float: left;
    color: #6f9718;
  }
}

@media (min-width: 576px) {
  [class*="tbl-cmn"] th,
  [class*="tbl-cmn"] td {
    padding: 15px;
  }
}

[class*="tbl-std"] table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 1px;
  background-color: #dadada;
}

[class*="tbl-std"] th,
[class*="tbl-std"] td {
  background-color: #fff;
}

[class*="tbl-std"] thead th {
  background-color: #8fc31f;
  color: #fff;
}

[class*="tbl-std"] tbody th {
  background-color: #f38784;
  color: #62b800;
}

[class*="tbl-std"] caption {
  margin-bottom: .5em;
  font-size: 120%;
  font-weight: bold;
}

[class*="tbl-std"] caption::before {
  content: "■";
  color: #c4e564;
}

[class*="tbl-std"] .noCaps {
  margin-bottom: .5em;
  font-size: 80%;
  font-weight: normal;
}

[class*="tbl-std"] .noCaps::before {
  display: none;
}

@media (max-width: 575px) {
  [class*="tbl-std"] th,
  [class*="tbl-std"] td {
    padding: 7.5px;
  }
  [class*="tbl-std"] .tbl-cmn--row,
  [class*="tbl-std"] .tbl-cmn--row tr,
  [class*="tbl-std"] .tbl-cmn--row th,
  [class*="tbl-std"] .tbl-cmn--row td {
    display: block;
  }
  [class*="tbl-std"] .tbl-cmnB--row,
  [class*="tbl-std"] .tbl-cmnB--row tr,
  [class*="tbl-std"] .tbl-cmnB--row th,
  [class*="tbl-std"] .tbl-cmnB--row td {
    display: block;
  }
}

@media (min-width: 576px) {
  [class*="tbl-std"] th,
  [class*="tbl-std"] td {
    padding: 15px;
  }
}

[class*="tbl-data"] table {
  width: 100%;
  background-color: #eeeeee;
  border-collapse: separate;
  border-spacing: 1px;
}

[class*="tbl-data"] th,
[class*="tbl-data"] td {
  padding: .5em;
  vertical-align: middle;
  background-color: #eeeeee;
}

[class*="tbl-data"] thead th,
[class*="tbl-data"] thead td {
  line-height: 1.1;
  text-align: center;
  background-color: #62b800;
  color: #eeeeee;
}

[class*="tbl-data"] thead td {
  font-size: 90%;
}

[class*="tbl-data"] tbody th {
  font-size: 90%;
  color: #62b800;
}

[class*="tbl-data"] tbody tr:nth-of-type(even) > * {
  background-color: #f38784;
}

[class*="tbl-data"] tbody td.u-ta-c {
  font-size: 150%;
  font-weight: bold;
  line-height: 1;
  color: #edb41e;
}

@media (max-width: 767px) {
  [class*="tbl-data"] tbody th {
    width: 26%;
  }
  [class*="tbl-data"] tbody td {
    width: 32%;
    white-space: nowrap;
  }
}

@media (min-width: 768px) {
  [class*="tbl-data"] tbody th {
    width: 25%;
  }
  [class*="tbl-data"] tbody td {
    width: 30%;
    white-space: nowrap;
  }
  [class*="tbl-data"] tbody td + td {
    width: 45%;
  }
}

[class*="tbl-data"] caption {
  margin-bottom: .5em;
  padding-left: .5em;
  border-left: 3px solid #c4e564;
  font-size: 90%;
  font-weight: bold;
  color: #62b800;
}

[class*="tbl-data"] caption em {
  font-size: 1.6rem;
  color: #edb41e;
}

[class*="tbl-data"] caption strong {
  font-size: 120%;
}

[class*="tbl-data"] .box-scroll + .box-scroll {
  margin-top: 1.5em;
}

/* 2-1-7.  List      [lst-***]
-------------------------------------------------- */
.tblist {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.lst-flow--item {
  position: relative;
  counter-increment: step;
}

.lst-flow--item + .lst-flow--item {
  margin-top: 45px;
}

.lst-flow--item::before {
  content: counter(step);
  position: absolute;
  top: -.9em;
  left: -.9em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
  border-radius: 100%;
  background-color: #8fc31f;
  color: #fff !important;
}

.lst-flow--item:not(:last-of-type)::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: -60px;
  left: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-width: 24px 18px;
  border-style: solid;
  border-color: #edb41e transparent transparent;
}

@media (min-width: 768px) {
  .lst-flow--item {
    width: calc(50% - 15px);
  }
  .lst-flow--item:not(:last-of-type)::after {
    content: none;
  }
  .lst-flow--item:nth-of-type(odd) {
    clear: left;
    float: left;
    margin-top: 0;
    margin-bottom: 60px;
  }
  .lst-flow--item:nth-of-type(even) {
    clear: right;
    float: right;
    margin-top: 60px;
  }
  .lst-flow--item:nth-of-type(4), .lst-flow--item:nth-of-type(6) {
    margin-top: 150px;
  }
  .lst-flow--item:last-of-type {
    margin-bottom: 0;
  }
}

.lst-step {
  padding: 15px;
  border-left: 4px solid #8fc31f;
  background-color: #f4fbe6;
}

.lst-step__head {
  font-size: 1.8rem;
  font-weight: 500;
  color: #8fc31f;
}

/* 2-1-8.  Nav       [nav-***]
-------------------------------------------------- */
.gBnrsWrap {
  border-top: 1px solid #eeeeee;
}

@media (max-width: 991px) {
  .gBnrsWrap {
    padding-bottom: 15px;
  }
}

@media (min-width: 992px) {
  .gBnrsWrap {
    padding-bottom: 15px;
  }
}

.gBnrs-item {
  text-align: center;
}

@media (max-width: 991px) {
  .gBnrs-item {
    width: 100%;
    padding: 15px;
    padding-bottom: 0;
  }
}

@media (min-width: 992px) {
  .gBnrs-item {
    padding: 30px 15px 15px;
  }
}

.gBnrs a {
  display: block;
}

.gBnrs [class*="btn-"] {
  padding-right: 0;
  padding-left: 0;
}

/* 2-1-9.  Button    [btn-***]
-------------------------------------------------- */
[class*="btns"] {
  display: flex;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;
  padding-top: 30px;
  font-size: 1.6rem;
}

.bArw::before,
.aArw::after {
  position: absolute;
  top: auto;
  bottom: auto;
  font-family: 'FontAwesome';
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-rendering: auto;
  line-height: 1;
  content: "\f105";
  font-size: 120%;
}

.bArw::before {
  right: 1em;
}

.aArw::after {
  left: 1em;
}

.bRet::before {
  position: absolute;
  top: auto;
  bottom: auto;
  font-family: 'FontAwesome';
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-rendering: auto;
  line-height: 1;
  content: "\f104";
  font-size: 120%;
}

.bRet::before {
  left: 1em;
}

/* color */
[class*="btn-color"] {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  padding: 1.2em 3em;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 50%, transparent 51%);
  background-color: #111;
  color: #fff !important;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
  transition: background .3s, color .3s;
}

.btn-color--b1 {
  background-color: #232323;
}

.btn-color--b1.is-hover {
  background-color: #3d3d3d;
}

.btn-color--b2 {
  background-color: #dadada;
}

.btn-color--b2.is-hover {
  background-color: #e7e7e7;
}

.btn-color--b3 {
  border: 2px solid rgba(255, 255, 255, 0.1);
  background-color: #eeeeee;
  color: #232323 !important;
  text-shadow: 1px 1px 0 rgba(35, 35, 35, 0.3);
}

.btn-color--b3.is-hover {
  background-color: #fbfbfb;
}

.btn-color--b4 {
  background-color: #ffffff;
  color: #62b800 !important;
  text-shadow: 1px 1px 0 rgba(98, 184, 0, 0.3);
}

.btn-color--b4.is-hover {
  background-color: #70d200;
  color: #ffffff !important;
}

.btn-color--t1 {
  background-color: #62b800;
}

.btn-color--t1.is-hover {
  background-color: #70d200;
}

.btn-color--t2 {
  background-color: #8fc31f;
}

.btn-color--t2.is-hover {
  background-color: #9fd922;
}

.btn-color--t3 {
  background-color: #c4e564;
}

.btn-color--t3.is-hover {
  background-color: #cce97a;
}

.btn-color--t4 {
  background-color: #ca1914;
}

.btn-color--t4.is-hover {
  background-color: #ea2d27;
}

.btn-color--t5 {
  background-color: #edb41e;
}

.btn-color--t5.is-hover {
  background-color: #ea2d27;
}

.btn-color--t6 {
  background-color: #fce483;
  color: #c89510 !important;
}

.btn-color--t6.is-hover {
  background-color: #fdefb5;
}

/* color */
[class*="btn-frame"] {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  padding: 1em 3em;
  background-color: transparent;
  color: #232323 !important;
  transition: border .3s, background .3s, color .3s;
}

.btn-frame--b1 {
  border-color: rgba(35, 35, 35, 0.65);
}

.btn-frame--b1.is-hover {
  border-color: #232323;
  background-color: #232323;
  color: #fff !important;
}

.btn-frame--b2 {
  border-color: rgba(218, 218, 218, 0.65);
}

.btn-frame--b2.is-hover {
  border-color: #dadada;
  background-color: #dadada;
}

.btn-frame--b3 {
  border-color: rgba(238, 238, 238, 0.65);
}

.btn-frame--b3.is-hover {
  border-color: #eeeeee;
  background-color: #eeeeee;
}

.btn-frame--b4 {
  border-color: rgba(255, 255, 255, 0.65);
}

.btn-frame--b4.is-hover {
  border-color: #ffffff;
  background-color: #ffffff;
  color: #62b800 !important;
}

.btn-frame--t1 {
  border-color: rgba(98, 184, 0, 0.65);
  color: #62b800 !important;
}

.btn-frame--t1.is-hover {
  border-color: #62b800;
  background-color: #62b800;
  color: #fff !important;
}

.btn-frame--t2 {
  border-color: rgba(143, 195, 31, 0.65);
  color: #8fc31f !important;
}

.btn-frame--t2.is-hover {
  border-color: #8fc31f;
  background-color: #8fc31f;
  color: #fff !important;
}

.btn-frame--t3 {
  border-color: rgba(179, 222, 56, 0.65);
  color: #b3de38 !important;
}

.btn-frame--t3.is-hover {
  border-color: #c4e564;
  background-color: #c4e564;
  color: #fff !important;
}

.btn-frame--t4 {
  border-color: rgba(234, 45, 39, 0.65);
  color: #ea2d27 !important;
}

.btn-frame--t4.is-hover {
  background-color: #ea2d27;
  color: #fff !important;
}

.btn-frame--t5 {
  border-color: rgba(237, 180, 30, 0.65);
  color: #edb41e !important;
}

.btn-frame--t5.is-hover {
  background-color: #edb41e;
  color: #fff !important;
}

.btn-frame--t6 {
  border-color: rgba(249, 207, 32, 0.65);
  color: #e0b606 !important;
}

.btn-frame--t6.is-hover {
  background-color: #f9cf20;
  color: #fff !important;
}

/* 2-1-10. Link      [lnk-***]
-------------------------------------------------- */
.lnk-tel {
  display: inline-flex;
  color: inherit !important;
}

@media (max-width: 767px) {
  .lnk-tel {
    pointer-events: auto;
  }
}

@media (min-width: 768px) {
  .lnk-tel {
    pointer-events: none;
  }
}

.lnk-fax {
  display: inline-block;
  pointer-events: none;
}

/* 2-1-11. Icon      [ico-***]
-------------------------------------------------- */
/* 2-1-12. Label     [lbl-***]
-------------------------------------------------- */
.forms .require,
.forms .anyitem {
  display: inline-block;
  position: relative;
  width: 4em;
  margin-top: .4rem;
  padding: 0;
  border-radius: .3rem;
  color: white;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
}

.forms .require {
  background-color: #ca1914;
}

.forms .anyitem {
  background-color: #6f9718;
}

@media (max-width: 767px) {
  .forms .require,
  .forms .anyitem {
    float: left;
    margin-right: 1em;
  }
}

@media (min-width: 768px) {
  .forms .require,
  .forms .anyitem {
    float: right;
  }
}

[class*="lbl-cmn"] {
  display: inline-block;
  border-radius: 4px;
  padding-right: .4em;
  padding-left: .4em;
  color: #fff;
}

.lbl-cmn--b1 {
  background-color: #232323;
}

.lbl-cmn--b2 {
  background-color: #dadada;
}

.lbl-cmn--b3 {
  background-color: #eeeeee;
}

.lbl-cmn--b4 {
  background-color: #ffffff;
}

.lbl-cmn--t1 {
  background-color: #62b800;
}

.lbl-cmn--t2 {
  background-color: #8fc31f;
}

.lbl-cmn--t3 {
  background-color: #c4e564;
}

.lbl-cmn--t4 {
  background-color: #ea2d27;
}

.lbl-cmn--t5 {
  background-color: #edb41e;
}

.lbl-cmn--t6 {
  background-color: #fdefb5;
}

/* 2-1-13. Form      [frm-***]
-------------------------------------------------- */
.forms fieldset,
.forms legend {
  margin: 0;
  padding: 0;
  border: 0;
}

.forms td label {
  display: inline-block;
  margin-right: 1.5em;
  padding: .25em 0;
  font-weight: 400;
}

.forms input[type="text"],
.forms input[type="email"],
.forms input[type="tel"],
.forms input[type="password"],
.forms select,
.forms option,
.forms textarea {
  width: 100%;
  padding: .3em .6em;
  border: 1px solid #aaa;
  border-radius: .4rem;
  background-color: #fcfcfc;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.2;
  outline: none;
}

.forms textarea {
  height: 8.5em;
}

.forms select {
  padding: .3em .6em;
  border: 1px solid #aaa;
  border-radius: .4rem;
  background-color: #fcfcfc;
  font-size: 1.6rem;
  font-weight: 400;
  outline: none;
}

.forms [class*="ttl-cell"] {
  padding: 0 !important;
  background-color: #fff !important;
}

.forms [class*="ttl-cell"] [class*="hdg"] {
  margin: 0 -1px;
  padding: 15px 0 !important;
  background-color: #fff !important;
  color: #7fad1c !important;
}

.forms .ttl-cell__1st [class*="hdg"] {
  margin: -1px -1px 0;
}

.forms [class*="ttl-cell"] i {
  color: #edb41e !important;
}

@media (max-width: 575px) {
  .forms th,
  .forms td,
  .forms td label,
  .forms input[type="text"],
  .forms input[type="email"],
  .forms input[type="tel"],
  .forms input[type="password"],
  .forms select,
  .forms option,
  .forms textarea {
    font-size: 1.4rem;
  }
}

@media (min-width: 576px) {
  .forms td {
    line-height: 2.2;
  }
}

@media (min-width: 768px) {
  .forms th {
    width: 240px;
  }
}

/* owlet parts */
.owlet-input-name-sei,
.owlet-input-name-mei {
  max-width: 30%;
  margin-right: .5em;
  margin-left: .5em;
}

.owlet-input-name-sei[name="input[name_sei]"] {
  margin-left: 1.4em;
}

.owlet-input-name-mei[name="input[name_mei]"] {
  margin-left: 1.4em;
}

[class*="owlet-input-tel"] {
  max-width: 4em;
  margin-right: .25em;
}

.owlet-input-tel2,
.owlet-input-tel3 {
  margin-left: .25em;
}

.zip1 {
  width: 3.2em !important;
  margin-right: .25em;
  margin-left: .25em;
}

.zip2 {
  width: 4em !important;
  margin-right: .50em;
  margin-left: .25em;
}

.prefectures {
  width: 100%;
  margin-top: .50em;
  margin-bottom: .25em;
}

.forms input[name="input[age]"] {
  width: 5em !important;
}

.forms input[name="input[age]"] + .owlet-input-sample {
  display: none;
}

/* sample */
.owlet-input-sample {
  margin-top: .5em;
  color: #666;
  font-size: 1.3rem;
  line-height: 1.2;
}

.owlet-input-sample:empty {
  display: none;
}

/* agreement */
.owlet-rules {
  display: flex;
  flex-direction: column-reverse;
  text-align: center;
}

[class*="tbl-"] .owlet-rules {
  color: #232323;
}

.owlet-rules-title,
.owlet-rules .require {
  display: none;
}

.owlet-rules-body {
  font-size: 1.2rem;
}

.owlet-rules-agree {
  display: inline-block;
  padding: .3em 0;
  font-size: 1.6rem;
}

.owlet-rules-agree input {
  margin-right: .5em;
}

/* alert */
.forms .alert {
  margin-top: 10px;
  font-weight: bold;
  line-height: 1.2;
  color: #ea2d27;
}

.forms .alert::before {
  content: "※";
  color: #ea2d27;
}

/* button */
.form_button {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.form_button a,
.form_button input[type="submit"] {
  overflow: hidden;
  display: inline-block;
  position: relative;
  max-width: 360px;
  min-width: 240px;
  margin: 7.5px 7.5px 0;
  padding: 15px 15px 16.66667px;
  font-size: 16px;
  line-height: 1.25;
  transition: all .3s;
  color: #fff !important;
  outline: none;
}

.form_button input[type="submit"] {
  border-radius: 4px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  background-color: #edb41e;
}

.form_button a {
  border-radius: 4px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  background-color: #494949;
  text-align: center;
}

.form_button input[disabled],
.form_button input[disabled]:hover,
.form_button input.disabled,
.form_button input.disabled:hover {
  cursor: default;
  background-color: rgba(218, 218, 218, 0.5) !important;
  text-shadow: 1px 1px 2px transparent;
  transform: none !important;
  color: rgba(255, 255, 255, 0.5) !important;
}

.owlet-rules + .form_button {
  margin-right: 0;
  margin-left: 0;
  padding: 0 !important;
}

.input_address > span {
  float: left;
  display: flex;
  align-items: center;
}

.zip2addr {
  border: 0;
  padding-bottom: .2em;
  font-size: 1.2rem !important;
  line-height: 2.2;
  outline: none !important;
  border-radius: 4px;
  background-color: #6f9718 !important;
  transition: background .3s;
}

@media (max-width: 575px) {
  .form_button {
    flex-direction: column-reverse;
    padding: 7.5px;
  }
  table + .owlet-rules {
    margin-top: 10px;
  }
  .owlet-rules + .form_button {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (min-width: 576px) {
  .form_button {
    flex-direction: column-reverse;
    padding: 7.5px;
  }
  table + .owlet-rules {
    margin-top: 15px;
  }
  .owlet-rules + .form_button {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

@media (min-width: 768px) {
  .form_button {
    flex-direction: row;
    padding: 15px;
  }
  table + .owlet-rules {
    margin-top: 15px;
  }
  .owlet-rules + .form_button {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .form_button input[type="submit"]:hover {
    background-color: #ca1914;
  }
  .form_button a.is-hover {
    background-color: #707070;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
  }
  .zip2addr {
    min-height: 32px;
  }
  .zip2addr:is-hover {
    background-color: #8fc31f !important;
  }
}

/* 2-1-14. Media     [mda-***]
-------------------------------------------------- */
[class*="mda-"] {
  position: relative;
}

[class*="mda-"] img {
  max-width: 100%;
}

[class*="mda-"] figure {
  display: inline-block;
  position: relative;
}

[class*="mda-"] figcaption {
  display: inline-block;
}

/* shadow */
.mda-fig__sdw,
.mda-fig__flt {
  margin-top: 15px;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .mda-fig__sdw,
  .mda-fig__flt {
    margin-top: 15px;
    margin-right: 45px;
  }
}

.mda-fig__sdw figure::before,
.mda-fig__flt figure::before {
  opacity: .75;
  content: "";
  z-index: -1;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 100%;
  height: 100%;
  margin: auto;
  background-image: linear-gradient(45deg, #8fc31f 0%, #62b800 100%);
  background-color: #8fc31f;
}

@media (min-width: 768px) {
  .mda-fig__sdw figure::before,
  .mda-fig__flt figure::before {
    top: 15px;
    left: 15px;
  }
}

@media (min-width: 768px) {
  .mda-fig__flt {
    float: left;
    float: left;
    max-width: 50%;
  }
}

/* cut */
.mda-fig__cut {
  position: relative;
}

.mda-fig__cut::before, .mda-fig__cut::after {
  z-index: 2;
  content: "";
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

@media (min-width: 768px) {
  .mda-fig__cut::before, .mda-fig__cut::after {
    width: 60px;
    height: 60px;
  }
}

.mda-fig__cut::before {
  top: 0;
  left: 0;
  background-image: url(../common_img/bg-lt.png);
  background-position: left top;
}

.mda-fig__cut::after {
  right: 0;
  bottom: 0;
  background-image: url(../common_img/bg-rb.png);
  background-position: right bottom;
}

/* 2-1-15. Card      [crd-***]
-------------------------------------------------- */
/* 2-1-16. Breadcrub
-------------------------------------------------- */
#breadcrumb ul {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  padding: 15px;
}

#breadcrumb li {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  color: rgba(35, 35, 35, 0.8);
}

#breadcrumb li + li::before {
  display: inline-block;
  float: left;
  content: "";
  width: 6px;
  height: 6px;
  margin: .5em;
  border-top: 1px solid #232323;
  border-right: 1px solid #232323;
  transform: rotate(45deg);
}

#breadcrumb a {
  display: block;
  text-decoration: underline;
  white-space: nowrap;
  color: #8fc31f;
}

#breadcrumb a:hover {
  color: #c4e564;
  text-decoration: none;
}

#breadcrumb li:nth-of-type(n+3) span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 767px) {
  #breadcrumb li,
  #breadcrumb li a {
    font-size: 11px;
  }
  #breadcrumb li + li::before {
    width: .4rem;
    height: .4rem;
  }
  #breadcrumb li:nth-of-type(1) a span {
    display: none;
  }
  #breadcrumb li:nth-of-type(1) a::before {
    font-family: 'FontAwesome';
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    text-rendering: auto;
    line-height: 1;
    content: "\f015";
  }
  #breadcrumb li:nth-of-type(n+3) span {
    max-width: 9em;
  }
}

@media (min-width: 768px) {
  #breadcrumb li:nth-of-type(n+3) span {
    max-width: 20em;
  }
}

/* 2-1-17. Pagination
-------------------------------------------------- */
.pagination {
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}

@media (min-width: 768px) {
  .pagination {
    margin-top: 45px;
    margin-bottom: 45px;
  }
}

.pager {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.pagenation {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.pagenation > * {
  overflow: hidden;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 3em;
  height: 3em;
  margin: 2px;
  border-radius: 4px;
  font-weight: bold;
  line-height: 1;
}

.pagenation .current {
  overflow: hidden;
  border-radius: 4px;
  background-color: #fff;
  border: 2px solid #c4e564;
  color: #c4e564;
}

.pagenation a {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: calc(100%);
  height: calc(100%);
  margin: -2px;
  line-height: 1;
  text-align: center;
  text-decoration: none !important;
  background-color: #8fc31f;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  border: 2px solid #8fc31f;
  color: #fff;
  transition: background .3s, box-shadow .3s, border .3s;
}

.pagenation a:hover {
  background-color: #c4e564;
  border-color: #c4e564;
  box-shadow: none;
}

@media (max-width: 767px) {
  .pager {
    padding-top: 30px;
  }
  .pagenation > * {
    width: 2.4em;
    height: 2.4em;
  }
}

@media (min-width: 768px) {
  .pager {
    padding-top: 40px;
    padding-bottom: 20px;
  }
  .pagenation > * {
    width: 3em;
    height: 3em;
  }
}

.page.is-hidden {
  display: none;
}

.page.is-visible {
  display: inline-flex;
}

/* 2-2. Animation [act-***]
-------------------------------------------------- */
/* 2-3. OverLayerConfig [z-index]
-------------------------------------------------- */
.l-header {
  z-index: 999;
}

.l-nav {
  z-index: 101;
}

.g-nav__bg {
  z-index: 101;
}

.bx-viewport {
  z-index: 501;
}

.bx-controls a {
  z-index: 502;
}

/* 2-4. Order [flex-order]
-------------------------------------------------- */
/* header */
.header-logo {
  order: 1;
}

.g-nav {
  order: 4;
}

.g-address {
  order: 2;
}

.l-mark {
  order: 3;
}

@media (min-width: 1200px) {
  .g-nav {
    order: 2;
  }
  .g-address {
    order: 3;
  }
  .l-mark {
    order: 4;
  }
}
