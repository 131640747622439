@charset "utf-8";
@import "../../modules/_import";

/* 1-1.  .l-wrapper
-------------------------------------------------- */
.l-wrapper {
  position: relative;
  overflow: hidden;
}

.l-standby {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $gutter 0;
  @include media(sm-max) {
    height: 60vh;
  }
  @include media(md) {
    height: 480px;
  }
  br {
    display: block !important;
  }
}
.no-article {
  display: none;
}

/* 1-2.  .l-container
-------------------------------------------------- */
.l-container {
  position: relative;
  max-width: $containerWidth;
  margin-right: auto;
  margin-left:  auto;
  padding-right: $gutter / 2;
  padding-left:  $gutter / 2;
}

.l-container__fluid {
  position: relative;
  max-width: 100%;
  padding-right: 0;
  padding-left:  0;
}

/* 1-3.  .l-header
-------------------------------------------------- */
.l-header {
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-top: 4px solid $color-t2;
  background-color: rgba(#fff,.95);
  @include media(xs) {
    min-width: 320px;
    height: 60px;
  }
  @include media(sm) {
    min-width: auto;
    height: 60px;
  }
  @include media(md) {
    min-width: auto;
    height: 80px;
  }
  @include media(xl) {
    height: 80px;
    padding-right: $gutter / 2;
  }
}
.header-logo {
  margin-right: auto;
  margin-left: 15px;
  line-height: 1;
  img {
    @include media(sm-max) {
      height: 30px;
    }
    @include media(md) {
      height: 40px;
    }
  }
}
/* 1-4.  l-footer
-------------------------------------------------- */
.g-footer {
  border-bottom: 4px solid $color-t1;
}
.l-footer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: auto;
  margin-left:  auto;
  padding-right: $gutter / 2;
  padding-left:  $gutter / 2;
  @include media(sm-max) {
    justify-content: center;
    padding-top:    $gutter / 2;
    padding-bottom: $gutter / 2;
    .fInfo {
      width: 100%;
    }
  }
  @include media(md) {
    justify-content: space-between;
    padding-top:    $gutter;
    padding-bottom: $gutter;
    .fInfo {
      width: 100%;
      padding-right: 15px;
    }
  }
  @include media(lg) {
    max-width: 960px;
    justify-content: space-between;
    padding-top:    $gutter * 1.5;
    padding-bottom: $gutter * 1.5;
    .fInfo {
      width: auto;
    }
    .gAdr-items {
      justify-content: flex-start;
    }
  }
  @include media(xl) {
    max-width: 1200px;
    justify-content: space-between;
    padding-top:    $gutter * 1.5;
    padding-bottom: $gutter * 1.5;
  }
  .no-wrap {
    width: 100%;
    flex-wrap: nowrap;
  }
}
.footer-logo {
  margin-right: auto;
  line-height: 1;
  a {
    display: inline-block;
  }
  @include media(sm-max) {
    margin-top: 0;
    margin-left: auto;
    text-align: center;
    img {
      height: 30px;
    }
  }
  @include media(md) {
    margin-top:  15px;
    margin-left: 15px;
    text-align: left;
    img {
      height: 40px;
    }
  }
  @include media(lg) {
    margin-top: 0;
  }
}
.footer-address {
  margin-top: 1em;
  @include media(md-max) {
    display: none;
  }
  @include media(lg) {
    padding-right: $gutter / 2;
    padding-left:  $gutter / 2;
    font-size: 1.3rem;
  }
}

/* pagetop */
.pagetop {
  border-top:    1px solid rgba($color-b2, .50);
  border-bottom: 1px solid rgba($color-b2, .50);
  background-color: rgba($color-b3, .50);
}
.pagetop a {
  display: block;
  font-size: 3.6rem;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  background-color: $color-t2;
  color: #fff;
  transition: background .3s;
  @include media(sm-max) {
    padding-top:    4px;
    padding-bottom: 6px;
  }
  @include media(md) {
    padding-top:    8px;
    padding-bottom: 12px;
  }
  @include media(lg) {
    &.is-hover {
      background-color: lighten($color-t2, 15%);
      color: #fff;
    }
  }
}

/* 1-5.  .l-nav
-------------------------------------------------- */
.l-nav {
  display: flex;
  width: 100vw;
}

/* 1-5-1. .globalNav */
.g-nav {
  display: flex;
  justify-content: flex-end;
  height: 100%;
}
.g-nav {
  width: 100vw;
}
.gNav-lnk__txt {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;
  padding-right: $gutter / 2;
  padding-left:  $gutter / 2;
  font-weight: bold;
  color: $color-b1 !important;
  transition: background .3s;
}
.g-nav__bg {
  display: none;
  opacity: 0;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgba(#000, .50);
  transition: opacity .3s;
}
.is-active .g-nav__bg {
  display: block;
  opacity: 1;
}

@include media(lg-max) {
  .g-nav {
    width: 100vw;
    height: 100vh;
    transform: translateX(100%);
    transition: transform .3s;
  }
  .is-active .g-nav {
    transform: translateX(0);
  }
  .gNav-items {
    overflow: auto;
    width: 100%;
    height: 100%;
    padding-right: $gutter / 2;
    padding-left:  $gutter / 2;
    background-color: rgba(#fff,.95);
  }
  .gNav-item {
    border-bottom: 1px solid $color-b2;
  }
  .gNav-item:first-of-type {
    border-top: 1px solid $color-b2;
  }
  .gNav-lnk__txt {
    display: block;
    padding: $gutter / 2;
    text-align: center;
    color: $color-t2 !important;
  }
}
@include media(xs) {
  .l-nav {
    width: 100%;
    height: calc(100vh - 60px);
  }
}
@include media(sm) {
  .l-nav {
    width: 100%;
    height: calc(100vh - 60px);
  }
}
@include media(md) {
  .l-nav {
    max-width: 320px;
    height: calc(100vh - 80px);
  }
  .gNav-btn,
  .gNav-tel {
    display: none;
  }
}
@media (min-width:1200px) and (max-width:1440px) {
  .gNav-lnk__txt {
    padding-right: 13px;
    padding-left:  13px;
  }
}
@include media(xl) {
  .l-nav {
    width: auto;
    max-width: 100%;
    height: auto;
    margin-right: 0;
    margin-left:  0;
  }
  .g-nav {
    position: relative;
    width: auto;
  }
  .gNav-items {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
  }
  .gNav-lnk__txt::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 0;
    height: 0;
    border: 8px solid transparent;
  }
  .gNav-lnk__txt:hover {
    background-color: $color-t2;
    background-image: linear-gradient(0deg, $color-t1 0%, $color-t2 100%);
    color: #fff !important;
  }
  .owl-in_home.gNav-lnk__txt,
  .owl-in_page_tree.gNav-lnk__txt {
    color: $color-t2 !important;
    &:hover {
      background-color: transparent;
      background-image: none;
    }
    &::before {
      border-top-color: $color-t2;
    }
  }
  .g-nav__bg,
  .is-active .g-nav__bg {
    display: none;
  }
  .gNav-item:nth-of-type(7) {
    display: none;
  }
}

/* 1-5-2. .headerAddress */
.g-address {
  display: flex;
  height: 100%;
}
.gAdr-item__num {
  display: block;
  margin-top: 12px;
  margin-right: 15px;
  font-weight: bold;
  line-height: 1.2;
  color: $color-t2;
  .gAdr-numbers--time {
    color: #999;
  }
}
.gNav-btn__mail {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  font-weight: 500;
  line-height: 1.2;
  border-radius: 4px;
  background-color: $color-t5;
  text-shadow: 1px 1px 1px rgba(#000, .30);
  color: #fff !important;
  transition: background .3s;
  span {
    font-size: 1.3rem;
  }
  i {
    font-size: 3.6rem;
  }
}
@include media(sm-max) {
  .l-header .gAdr-items,
  .l-footer .gAdr-numbers--fax {
    display: none;
  }
  .gAdr-item__btn,
  .gNav-btn {
      margin-top: 15px;
  }
  .gNav-btn__mail {
    padding-top:    12px;
    padding-bottom: 12px;
  }
  .gNav-btn__mail br {
    display: none;
  }
  .gNav-btn__mail span {
    font-size: 1.6rem;
  }
  .gNav-btn__mail i {
    font-size: 2.4rem;
  }
  .gAdr-numbers--tel, 
  .gNav-tel {
      margin-top: 15px;
      text-align: center;
      .lnk-tel {
        flex-direction: column;
        font-size: 2.0rem;
        font-weight: bold;
        line-height: 1.1;
        color: $color-t2 !important;
      }
      br {
        display: none;
      }
      span:nth-of-type(2) {
        display: inline-block;
        margin-top: .5em;
        font-weight: 400;
        color: #999 !important;
      }
  }
  .gAdr-numbers--time {
    font-weight: normal;
    text-align: center;
  }
  
}
@include media(md) {
  .gAdr-items {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
  }
  .gAdr-item__btn {
    padding-top: 12px;
  }
}
@include media(lg) {
  .gAdr-items {
    position: relative;
    margin-left:  15px;
  }
  .gNav-btn__mail:hover {
    background-color: $color-t4;
  }
}

/* 1-5-3. .footerNav */
.fNav {
    width: 100%;
}
.fNav-items {
  display: flex;
  justify-content: flex-end;
}
.fNav-item {
  padding-right: $gutter / 2;
  padding-left:  $gutter / 2;
}
@include media(md-max) {
  .fNav {
    display: none;
  }
}
@include media(lg) {
  .fNav-item {
    a {
      color: $color-b1 !important;
      &:hover {
        color: $color-t2 !important;
      }
    }
  }
  .fNav-item__head {
    display: block;
    margin-top: 10px;
    font-weight: bold;
    & + & {
      margin-top: $gutter / 2;
    }
  }
  .fNav-item__desc ul {
    padding-top: .25em;
  }
  .fNav-item__desc li {
    padding-top: .25em;
    color: $color-t2;
  }
}

/* 1-6. .l-mark
-------------------------------------------------- */
.l-mark {
  position: relative;
  overflow: hidden;
  margin-left: 15px;
  font-size: 2.4rem;
  text-decoration: none;
  vertical-align: middle;
  text-align: center;
  transition: .3s;
  cursor: pointer;
  @include media(sm-max) {
    width:  56px;
    height: 56px;
    margin-left: 8px;
  }
  @include media(md) {
    width:  76px;
    height: 76px;
    margin-left: 15px;
  }
  @include media(xl) {
    display: none;
  }
}

/* 1-6-1. .mark */
.mark {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $color-t2;
  &.is-active {
    background-color: transparent;
  }
  & > * {
    position: relative;
    text-decoration: none;
  }
  & > i {
    will-change: transform;
  }
}

.markMenu {
  width: 100%;
  height: 1.8rem;
  position: relative;
  &__middle,
  &__top,
  &__bottom {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12px;
    width: 24px;
    height: 2px;
    background-color: #fff;
    transition-duration: .4s;
    transition-timing-function: cubic-bezier(.25,.74,.22,.99);
  }
  &__middle {
    margin-top: -2px;
    transition: opacity .25s;
    opacity: 1;
  }
  &__top {
    margin-top: -9px;
  }
  &__bottom {
    margin-top: 5px;
  }
}

.mark.is-active {
  & .markMenu__middle,
  & .markMenu__top,
  & .markMenu__bottom {
    background-color: $color-t1;
  }
  & .markMenu__middle {
    opacity: 0;
  }
  & .markMenu__top {
    transform: rotate(45deg) translate(5px, 5px);
  }
  & .markMenu__bottom {
    transform: rotate(-45deg) translate(5px, -5px);
  }
}

.l-mark.is-fixed {
  & .markMenu__middle,
  & .markMenu__top,
  & .markMenu__bottom {
    background-color: #fff;
  }
}

/* 1-7.  .l-main
-------------------------------------------------- */
.l-main {}

