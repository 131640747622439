@charset "utf-8";
//path
$img_path:'../images/';
$cmn-img_path:'../common_img/';
//breakpoint
$screen-xs-min: 0;
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xs-max: $screen-sm-min - 1;
$screen-sm-max: $screen-md-min - 1;
$screen-md-max: $screen-lg-min - 1;
$screen-lg-max: $screen-xl-min - 1;
//gutter
$gutter: 30px;
//width
$containerWidth: 1200px;
//baseColor 
$color-b1: #232323;
$color-b2: #dadada;
$color-b3: #eeeeee;
$color-b4: #ffffff;
//themeColor
$color-t1: #62b800;
$color-t2: #8fc31f;
$color-t3: #c4e564;
$color-t4: #ea2d27;
$color-t5: #edb41e;
$color-t6: #fdefb5;

//bodyColor
$color-body: #fff;
//noChangeColor
$freedialColor1: #009f41;
$freedialColor2: #0082ac;
//fontSet
//$ff-base : Lato, "Noto Sans JP", "Noto Sans Japanese", sans-serif;
$ff-base : "Noto Sans Japanese", "メイリオ", Meiryo, sans-serif;
$ff-rale : 'Raleway', sans-serif;
$ff-noto : "Noto Sans Japanese", sans-serif;
$ff-yugo : "游ゴシック", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", Osaka, sans-serif;
$ff-sans : "メイリオ", Meiryo, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", Osaka, sans-serif;body {
$ff-font: -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
}
//fontColor
$color-text: rgba($color-b1, 1);
$color-link: rgba($color-t2, 1);
$color-visited: rgba($color-t2, 1);
$color-hover: rgba($color-t3, 1);