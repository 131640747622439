@charset "utf-8";
/*! -----------------------------------------------
common css 共通クラス
--------------------------------------------------
0. Foundation
   0-1. Base
   0-2. Config
        0-2-1. font family
        0-2-2. color management
        0-2-3. lightbox
1. Layout
   1-1.  .l-wrapper
   1-2.  .l-container
   1-3.  .l-header
   1-4.  .l-footer
   1-5.  .l-nav
        1-4-1. .globalNav
        1-4-2. .footerNav
   1-6.  .l-mark
        1-6-1. .mark
   1-7.  .l-main
2. Object
   2-1. Component
        2-1-1.  Wrap      [***Wrap]
        2-1-2.  Box       [box-***]
        2-1-3.  Headings  [hdg-***]
        2-1-4.  Title     [ttl-***]
        2-1-5.  Paragraph [prg-***]
        2-1-6.  Table     [tbl-***]
        2-1-7.  List      [lst-***]
        2-1-8.  Nav       [nav-***]
        2-1-9.  Button    [btn-***]
        2-1-10. Link      [lnk-***]
        2-1-11. Icon      [ico-***]
        2-1-12. Label     [lbl-***]
        2-1-13. Form      [frm-***]
        2-1-14. Media     [mda-***]
        2-1-15. Card      [crd-***]
        2-1-16. Breadcrumb
        2-1-17. Pagination
   2-2. Animation [act-***]
   2-3. OverLayerConfig [z-index]
   2-4. Order [flex-order]
*/

/* -----------------------------------------------
 [0. Foundation]
-------------------------------------------------- */
@import "partials/base/_0-0-foundation";

/* -----------------------------------------------
 [1. Layout]
-------------------------------------------------- */
@import "partials/base/_1-0-layout";

/* -----------------------------------------------
 [2. Object]
-------------------------------------------------- */
@import "partials/base/_2-1-component";
@import "partials/base/_2-2-animation";
@import "partials/base/_2-3-overlayer";
@import "partials/base/_2-4-order";