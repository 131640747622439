@charset "utf-8";
@import "../../../modules/_import";

.forms fieldset,
.forms legend {
  margin: 0;
  padding: 0;
  border: 0;
}
.forms td label {
  display: inline-block;
  margin-right: 1.5em;
  padding: .25em 0;
  font-weight: 400;
}
.forms input[type="text"],
.forms input[type="email"],
.forms input[type="tel"],
.forms input[type="password"],
.forms select,
.forms option,
.forms textarea {
  width: 100%;
  padding: .3em .6em;
  border: 1px solid #aaa;
  border-radius: .4rem;
  background-color: #fcfcfc;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.2;
  outline: none;
}
.forms textarea {
  height: 8.5em;
}
.forms select {
  padding: .3em .6em;
  border: 1px solid #aaa;
  border-radius: .4rem;
  background-color: #fcfcfc;
  font-size: 1.6rem;
  font-weight: 400;
  outline: none;
}
.forms [class*="ttl-cell"] {
  padding: 0 !important;
  background-color: #fff !important;
}
.forms [class*="ttl-cell"] [class*="hdg"] {
  margin: 0 -1px;
  padding: 15px 0 !important;
  background-color: #fff !important;
  color: darken($color-t2, 5%) !important;
}
.forms .ttl-cell__1st [class*="hdg"] {
  margin: -1px -1px 0;
}
.forms [class*="ttl-cell"] i {
  color: $color-t5 !important;
}

@include media(xs){
  .forms th,
  .forms td,
  .forms td label,
  .forms input[type="text"],
  .forms input[type="email"],
  .forms input[type="tel"],
  .forms input[type="password"],
  .forms select,
  .forms option,
  .forms textarea {
    font-size: 1.4rem;
  }
}
@include media(sm){
  .forms td {
    line-height: 2.2;
  }
}
@include media(md){
  .forms th {
    width: 240px;
  }
}
/* owlet parts */
.owlet-input-name-sei,
.owlet-input-name-mei {
  max-width: 30%;
  margin-right: .5em;
  margin-left:  .5em;
}
.owlet-input-name-sei[name="input[name_sei]"] {
  margin-left: 1.4em;
}
.owlet-input-name-mei[name="input[name_mei]"] {
margin-left: 1.4em;
}

[class*="owlet-input-tel"] {
  max-width: 4em;
  margin-right: .25em;
}
.owlet-input-tel1 {
}
.owlet-input-tel2,
.owlet-input-tel3 {
  margin-left:  .25em;
}
.zip1 {
  width: 3.2em !important;
  margin-right: .25em;
  margin-left:  .25em;
}
.zip2 {
  width: 4em !important;
  margin-right: .50em;
  margin-left:  .25em;
}
.prefectures {
  width: 100%;
  margin-top:    .50em;
  margin-bottom: .25em;
}
.forms input[name="input[age]"] {
  width: 5em !important;
}
.forms input[name="input[age]"] + .owlet-input-sample {
  display: none;
}

/* sample */
.owlet-input-sample {
  margin-top: .5em;
  color: #666;
  font-size: 1.3rem;
  line-height: 1.2;
}
.owlet-input-sample:empty {
  display: none;
}

/* agreement */
.owlet-rules {
  display: flex;
  flex-direction: column-reverse;
  text-align: center;
  [class*="tbl-"] & {
    color: $color-b1;
  }
}
.owlet-rules-title,
.owlet-rules .require {
  display: none;
}
.owlet-rules-body {
  font-size: 1.2rem;
}
.owlet-rules-agree {
  display: inline-block;
  padding: .3em 0;
  font-size: 1.6rem;
}
.owlet-rules-agree input {
  margin-right: .5em;
}

/* alert */
.forms .alert {
  margin-top: 10px;
  font-weight: bold;
  line-height: 1.2;
  color: $color-t4;
}
.forms .alert::before {
  content: "※";
  color: $color-t4;
}
/* button */
.form_button {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.form_button a,
.form_button input[type="submit"] {
  overflow: hidden;
  display: inline-block;
  position: relative;
  max-width: 360px;
  min-width: 240px;
  margin:($gutter / 4) ($gutter / 4) 0;
  padding: ($gutter / 2) ($gutter / 2) ($gutter / 1.8);
  font-size: 16px;
  line-height: 1.25;
  transition: all .3s;
  color: #fff !important;

  outline: none;
}
.form_button input[type="submit"] {
  border-radius: 4px;
  text-shadow: 1px 1px 2px rgba(0,0,0,.60);
  background-color: $color-t5;
}
.form_button a {
  border-radius: 4px;
  text-shadow: 1px 1px 2px rgba(0,0,0,.60);
  background-color:lighten($color-b1, 15%);
  text-align: center;
}

.form_button input[disabled],
.form_button input[disabled]:hover,
.form_button input.disabled,
.form_button input.disabled:hover {
  cursor: default;
  background-color: rgba($color-b2, .50) !important;
  text-shadow: 1px 1px 2px rgba(0,0,0,0);
  transform: none !important;
  color: rgba(#fff, .50) !important;
}
.owlet-rules + .form_button {
  margin-right: 0;
  margin-left:  0;
  padding: 0 !important;
}
.input_address > span {
  float: left;
  display: flex;
  align-items: center;
}
.zip2addr {
  border: 0;
  padding-bottom: .2em;
  font-size: 1.2rem !important;
  line-height: 2.2;
  outline: none !important;
  border-radius: 4px;
  background-color: darken($color-t2, 10%) !important;
  transition: background .3s;
}


@include media(xs){
  .form_button {
    flex-direction: column-reverse;
    padding: $gutter / 4;
  }
  table + .owlet-rules {
    margin-top: $gutter / 3;
  }
  .owlet-rules + .form_button {
    margin-top: $gutter / 3;
    margin-bottom: $gutter / 3;
  }
}
@include media(sm){
  .form_button {
    flex-direction: column-reverse;
    padding: $gutter / 4;
  }
  table + .owlet-rules {
    margin-top: $gutter / 2;
  }
  .owlet-rules + .form_button {
    margin-top: $gutter / 2;
    margin-bottom: $gutter / 2;
  }
}
@include media(md){
  .form_button {
    flex-direction: row;
    padding: $gutter / 2;
  }
  table + .owlet-rules {
    margin-top: $gutter / 2;
  }
  .owlet-rules + .form_button {
    margin-top: $gutter / 2;
    margin-bottom: $gutter / 2;
  }
  
  .form_button input[type="submit"]:hover {
    background-color: darken($color-t4, 10%);
  }
  .form_button a.is-hover {
    background-color: lighten($color-b1, 30%);
    text-shadow: 1px 1px 2px rgba(0,0,0,.15);
  }
  .zip2addr {
    min-height: 32px;
    &:is-hover {
      background-color: $color-t2 !important;
    }
  }
}