@charset "utf-8";
@import "../../../modules/_import";

.l-wrapper:not(.page-) section [class*="Wrap"] {
  padding-top:    $gutter;
  padding-bottom: $gutter * 1.5;
  @include media(sm) {
    padding-top:    $gutter * 1.5;
    padding-bottom: $gutter * 1.5;
  }
}
.l-wrapper:not(.page-) section:first-of-type [class*="Wrap"] {
  padding-top: 0;
  @include media(sm) {
    padding-top: $gutter / 2;
  }
}

/* line - bg */
.grayLine,
.greenLine {
  background-repeat: repeat;
  background-position: 0 0;
  background-size: 640px 480px;
  & > [class*="Wrap"] {
    position: relative;
    background-image: -webkit-linear-gradient(left, rgba($color-t1, .80) 0%, rgba($color-t2, .10) 50%);
    background-image: linear-gradient(to right, rgba($color-t1, .80) 0%, rgba($color-t2, .10) 50%);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100%;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      width: 10%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}
.grayLine {
  background-image: url(../common_img/bg-patturn-g.png);
  background-color: $color-b3;
  & > [class*="Wrap"] {
    background-image: -webkit-linear-gradient(left, rgba($color-b4, .80) 0%, rgba($color-b3, .10) 50%);
    background-image: linear-gradient(to right, rgba($color-b4, .80) 0%, rgba($color-b3, .10) 50%);
  }
  & [class$="Txt"] {
    color: #fff;
  }
}
.greenLine {
  background-image: url(../common_img/bg-patturn.png);
  background-color: $color-t2;
  & > [class*="Wrap"] {
    background-image: -webkit-linear-gradient(left, rgba($color-t1, .80) 0%, rgba($color-t2, .10) 50%);
    background-image: linear-gradient(to right, rgba($color-t1, .80) 0%, rgba($color-t2, .10) 50%);
  }
  & [class$="Txt"] {
    color: #fff;
  }
}
