@charset "utf-8";
@import "../../modules/_import";

/* 2-3. OverLayerConfig [z-index]
-------------------------------------------------- */
.l-header {
  z-index: 999;
}
.l-nav {
  z-index: 101;
}
.g-nav__bg {
  z-index: 101;
}
.bx-viewport {
  z-index: 501;
}
.bx-controls a {
  z-index: 502;
}