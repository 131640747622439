@charset "utf-8";
@import "../../../modules/_import";

//form
.forms .require,
.forms .anyitem {
  display: inline-block;
  position: relative;
  width: 4em;
  margin-top: .4rem;
  padding: 0;
  border-radius: .3rem;
  color: white;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
}
.forms .require {
  background-color: darken($color-t4, 10%);
}
.forms .anyitem {
  background-color: darken($color-t2, 10%);
}
@include media(sm-max){
  .forms .require,
  .forms .anyitem {
    float: left;
    margin-right: 1em;
  }
}
@include media(md){
  .forms .require,
  .forms .anyitem {
    float: right;
  }
}

//:color
[class*="lbl-cmn"] {
  display: inline-block;
  border-radius: 4px;
  padding-right: .4em;
  padding-left:  .4em;
  color: #fff;
}

$lbl-color-b: $color-b1, $color-b2, $color-b3, $color-b4;
@each $color in $lbl-color-b {
  $i: index($lbl-color-b, $color);
  .lbl-cmn--b#{$i} { background-color: $color; }
}

$lbl-color-t: $color-t1, $color-t2, $color-t3, $color-t4, $color-t5, $color-t6;
@each $color in $lbl-color-t {
  $i: index($lbl-color-t, $color);
  .lbl-cmn--t#{$i} { background-color: $color; }
}